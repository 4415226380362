<template>
  <div
    class="form-group form-group-slider col-sm"
    :class="{ [`is-invalid`]: errors[id] }"
  >
    <label v-if="label" :for="id"
      >{{ label }} <small v-if="hint">{{ $t(hint) }}</small></label
    >
    <div class="slider-content">
      <slot />
      <veeno
        :range="range"
        :handles="value"
        :step="step"
        :connect="connect"
        :options="options"
        @input="onInput"
      >
        {{ value }}
      </veeno>
    </div>
    <small
      v-if="errors[id]"
      class="form-text text-danger"
      v-html="errors[id].message"
    ></small>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import 'nouislider/distribute/nouislider.min.css';

import veeno from 'veeno';

export default {
  components: {
    veeno,
  },
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: {
      type: Number || String,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    range: {
      type: Object,
      default: () => {
        return {};
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uuid(),
    },
    hint: {
      type: String,
      default: '',
    },
    connect: {
      type: [Array],
      default: () => {
        return [true, false];
      },
    },
    options: {
      type: Object,
      default: () => ({
        start: [0],
        connect: [true, false],
        range: {
          'min': 0,
          'max': 100
        }
      })
    },
  },
  methods: {
    onInput(e) {
      if (parseInt(e) < this.min) {
        this.$emit('input', this.min);
      } else {
        this.$emit('input', parseInt(e));
      }
    },
  },
};
</script>
<style lang="scss">
.form-group-slider {
  label {
    margin-bottom: 0.6rem;
    display: flex;
    align-items: center;
    small {
      font-size: 0.7rem;
      font-weight: 500;
      margin-left: auto;
      color: var(--text-light);
    }
  }
  .slider-content {
    display: flex;
    align-items: center;
    h4 {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin: 0;
      line-height: 1rem;
      position: relative;
      left: -0.5rem;
      min-width: 60px;
      color: var(--color-primary);
      small {
        display: block;
        font-size: 0.8rem;
        color: var(--text-medium);
      }
    }
  }
}
// ui sliders
.veeno {
  background: var(--bg-medium);
  border-radius: 100px;
  box-shadow: 0;
  border: none;
  height: 0.6rem;
  width: 100%;
  .noUi-connects {
    border-radius: 100px;
    .noUi-connect {
      border-radius: 100px;
      background: var(--color-primary);
    }
  }
  .noUi-handle {
    border-radius: 100px;
    width: 1.4rem;
    height: 1.4rem;
    background: var(--color-primary);
    box-shadow: none;
    border: 3px solid var(--bg-light);
    right: -2px !important;
    &::before,
    &::after {
      display: none;
    }
  }
  .veeno-span {
    display: none;
  }
}
</style>
