export function formatPrice(amount, suffix) {
  const [ price, cents ] = amount.toFixed(2).split('.');
  
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD'
  });
  
  const output = (cents === '00') ? `<small>$</small>${formatter.format(price)}` : `<small>$</small>${formatter.format(price)}<small>.${cents}</small>`;
  
  return `${output}${suffix ? `<small class="suffix">${suffix}</small>` : ''}`;
}

export function formatInlinePrice(amount) {
  
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(amount);
  
}