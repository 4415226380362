<template>
  <div class="form-group col-sm" :class="{ [`is-invalid`]: errors[id] }">
    <label v-if="label" :class="{[`is-optional`]: optional}" :for="id">{{ label }} <small v-if="optional">{{$t('Optional')}}</small></label>
    <slot name="info"></slot>
    <div class="input-group">
      <Icon class="form-select-icon" family="fas" name="chevron-down" />
      <select
        :id="id"
        :ref="id"
        :value="value"
        :placeholder="placeholder"
        :aria-label="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        class="form-control"
        :class="{['is-empty']: isEmpty, ...inputClass}"
        @focus="onFocus"
        @blur="onBlur"
        @input="updateValue"
        @click="defaultAction"
      >
        <option v-if="placeholder" value="">{{placeholder}}</option>
        <slot></slot>
      </select>
    </div>
    <small v-if="errors[id]" class="form-text text-danger" v-html="errors[id].message"></small>
    <small v-if="description" class="form-text" v-html="description"></small>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import { v4 as uuid } from 'uuid';

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: String,
      default: uuid()
    },
    value: {type: [String, Number], default: ''},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    inputClass: {type: String, default: ''},
    description: {type: String, default: ''},
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    focus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    // if enabled will strictly show the optional message
    optional: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    // auto select text when focusing input
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEmpty: false
    }
  },
  watch: {
    // watch focus to programmatically focus again
    focus(isEnabled) {
      if (isEnabled && this.id) {
        setTimeout(() => {
          this.$refs[this.id].focus();
        }, 200);
      }
    },
    value(val) {
      this.isEmpty = val === '';
    }
  },
  mounted() {
    this.isEmpty = this.$refs[this.id].value === '';
    if (this.focus && this.id) {
      setTimeout(() => {
        if (this.$refs[this.id]) this.$refs[this.id].focus();
      }, 200);
    }
  },
  methods: {
    updateValue: function (e) {
      this.$emit('input', e.target.value);
      this.isEmpty = e.target.value === '';
    },
    async defaultAction() {
      this.$emit('click');
    },
    onFocus() {
      this.$emit('focus');
    },
    addFocus(){
      this.$refs[this.id].focus();
    },
    onBlur() {
      this.$emit('blur');
    }
  },
};
</script>
<style lang="scss">
select.is-empty, select.is-empty:focus { color: var(--text-muted); }

label.is-optional {
  display:flex;
  align-items:center;
  small {
    font-size:.7rem;
    font-weight:500;
    margin-left:auto;
    color:var(--text-light);
  }
}

</style>
