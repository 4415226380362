export const HTTP = { USE_JSON: 'useJson' };

/**
 * workflowNodeTypes must match nodes component names
 * it is used to define what to render as well
 */
export const workflowNodeTypes = {
  START: 'StartNode',
  END: 'EndNode',
  DECISION: 'DecisionNode',
  ACTION: 'ActionNode',
  PLUS: 'PlusNode',
};

export const workflowDropAreaGroup = 'workflow-node-dropzone';

export const tableSettings = {
  defaults: {
    sortable: false,
    align: 'center',
    width: 'auto',
  },
};

/**
 * gets the first p tag or message
 * Example of matches:
 * HTML pages:
 * <p style="font-family: Arial, Helvetica; font-size: 14px; padding: 0px; margin: 0px">Sorry, we couldn't find the page you are looking for</p>
 *
 * XML errors
 * <message>Internal error</message>
 */
export const blobErrorPattern =
  // eslint-disable-next-line no-useless-escape
  /<(p[\s0-9a-zA-Z="',\-:;\.]*\s?|message)>(.*)<\/(p\s?|message)>/gm;

export const INVALID_USERNAME_PASSWORD =
  'Invalid Username or Password. Password is Case Sensitive.';

//  Intercom integration

export const intercom = {
  id: 'et9ue9id',
  src: 'https://widget.intercom.io/widget/et9ue9id',
  api: 'https://api-iam.intercom.io',
};
