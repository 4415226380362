<template>
  <div class="details-section">
    <Loader v-if="isLoading" loading full />

    <div class="modal-heading">
      <h2>{{ product.name }}</h2>
      <p>
        {{
          $t('Additional storage for {site}', {
            site: currentProps && currentProps.site.deploymenturl,
          })
        }}
      </p>
    </div>

    <form @submit.prevent="onClickPrimary">
      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>

      <div v-if="storage.allowusertobuy" class="row mb-3">
        <SlideInputBox
          id="storage"
          v-model="form.storage"
          :label="$t('Additional Storage')"
          :hint="$t('Drag the slider below to change')"
          :range="{
            min: 0,
            max: 5000
          }"
          :step="100"
          :errors="errors">
          <h4>
            {{(form.storage >= 1000) ? form.storage / 1000 : form.storage}}
            <small v-if="form.storage >= 1000">{{$t('TB')}}</small>
            <small v-else>{{$t('GB')}}</small>
          </h4>
        </SlideInputBox>
      </div>


      <div class="subtotal-section mt-1">
        <div class="total-description">
          <h4 class="mb-0">{{ $t('Additional Storage') }}</h4>
          <p></p>
        </div>
        <strong v-if="form.storage >= 1000" class="pricing">{{form.storage / 1000}} TB</strong>
        <strong v-else class="pricing">{{form.storage}} GB</strong>
      </div>


      <div class="total-section mt-3">
        <div class="total-description">
          <h4>{{ $t('Total') }}</h4>
          <p>
            {{$t('Billed anually')}}
            <Icon
               v-tooltip="{
                 content: $t('For purchasing more than a year, please contact sales.'),
               }"
               class="ml-1"
               name="info-circle"
               family="fas"
             />
          </p>
        </div>
        <div class="pricing">
          <div class="price" v-html="formatPrice(totalPrice)"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import SlideInputBox from '@/components/SlideInputBox';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';
import dayjs from 'dayjs';

export default {
  name: 'AddStorage',
  components: {
    Loader,
    SlideInputBox,
    Icon
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        storage: 0,
      },
      errors: {},
    };
  },
  computed: {
    totalPrice() {
      return (this.form.storage / 100) * this.storageRage;
    },
    storage() {
      return this.products[this.currentProps.group] && this.products[this.currentProps.group].storage ? this.products[this.currentProps.group].storage : {}
    },
    product() {
      return this.$store.getters['checkout/productByLicenseType'](
        this.currentProps.site.licensetype
      );
    },
    isLoading() {
      return this.$store.state.loading['checkout/addItems'];
    },
    order() {
      return (
        this.$store.state.checkout.singleOrder[this.currentProps.orderNumber] ||
        {}
      );
    },
    isLicenceExpired() {
      return this.currentProps.site.daysremaining === 0;
    },
    expirityDate(){
      return dayjs(this.currentProps.site.expirydate).format('DD MMMM YYYY');
    },
    storageRage(){
      return this.storage.price;
    }
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        if (form.quantity >= 100) {
          this.useInputForAmountOfUsers = true;
        }
      },
    },
  },
  mounted() {
    // this.getSaleCartOrder();

    this.form.storage = (this.storage.minimumquantitynew * 100);

    this.$emit('settings', {
      primary: 'Checkout',
      secondary: this.currentProps.previousStep ? 'Back' : 'Cancel',
    });
  },
  methods: {
    async getSaleCartOrder() {
      // const order = await this.$store.dispatch('checkout/getSaleOrderCart');
      await this.$store.dispatch(
        'checkout/renewLicense',
        this.currentProps.site.id
      );
    },
    async onClickPrimary() {
      this.errors = {};

      if(!this.form.quantity && !this.form.storage){
        this.errors._message = 'Shopping cart is empty';
        return;
      }

      if (
        this.form.quantity < this.product.minimumquantityupgrade
      ) {
        this.errors = {
          quantity: {
            message: this.$t(
              'The minimum amount of seats for this license is {minimum}',
              { minimum: this.product.minimumquantityupgrade }
            ),
          },
        };
        return;
      }

      let payload = {};

      if(this.form.storage > 0) {
        payload['items[0][number]'] = this.storage.number;
        payload['items[0][name]'] = this.storage.name;
        payload['items[0][desc]'] = this.storage.description;
        payload['items[0][quantity]'] = this.form.storage / 100;
      }
      
      const response = await this.$store.dispatch('checkout/addItems', payload);
      
      console.log(response);

      this.errors = {};

      this.$emit('changeStep', {
        step: 'summary',
        props: { orderNumber: this.order.order_number },
      });
    },
    onClickSecondary() {
      if (this.currentProps.previousStep) {
        this.$emit('changeStep', { step: this.currentProps.previousStep });
      } else {
        this.$modal.hide('checkout');
      }
    },
    formatInlinePrice,
    formatPrice,
  },
};
</script>
