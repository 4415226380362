<template>
  <Modal
    name="trial-extend"
    :title="$t('Extend your Trial')"
    class="fixed-top checkout-modal"
  >
    <div class="product-section">
      <Loader v-if="isLoading" loading full />

      <div class="modal-heading">
        <h2>{{ $t('FileCloud Trial') }}</h2>
        <p>{{ $t('Need more time to test FileCloud?') }}</p>
      </div>

      <form>
        <div v-if="errors._message" class="alert alert-danger">
          {{ errors._message }}
        </div>

        <div class="form-group" :class="{ [`is-invalid`]: errors['reason'] }">
          <label for="reason">{{ $t('Tell us more about your needs') }}</label>
          <textarea
            id="reason"
            v-model="form.reason"
            class="form-control"
            rows="3"
          ></textarea>
          <small v-if="errors['reason']" class="form-text text-danger">{{
            errors['reason'].message
          }}</small>
        </div>
      </form>

    </div>
    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-outline-primary px-5 ml-auto mr-3"
            @click="onClose"
          >
            {{ $t('Close') }}
          </button>
          <button
            class="btn btn-primary px-5 ml-auto ml-3"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ExtendTrial',
  components: {
    Loader,
    Modal,
    SupportBlock,
  },
  data() {
    return {
      errors: {},
      form: {
        reason: '',
        email: 'sales@codelathe.com',
        name: 'FileCloud Support',
        subject:
          'FileCloud Portal Customer Support Request for Trial Extension',
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['sites/startFreeTrial'];
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
    emailBody() {
      return `
      <p>FileCloud Support,</p>
      <b>${this.user.customername}</b> (${this.user.email}) has requested trial extension with the following Reason for Trial Extension:
      </br>
      </br>
      ============== Reason ==================
      </br>
      ${this.form.reason}
      </br>
      ==========================================
      </br>
      `;
    },
  },
  methods: {
    async onSubmit() {
      this.errors = {};


      if (!this.form.reason) {
        this.errors = {
          reason: {
            message: this.$t('This field is required'),
          },
        };
        return;
      }

      const response = await this.$store.dispatch('core/submitemail', {
        sendto: this.form.email,
        sendtoname: this.form.name,
        subject: this.form.subject,
        emailbody: this.emailBody,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.form.reason = '';
      this.$toast.info(this.$t('Trial requested! We will contact you soon.'));
      this.$modal.hide('trial-extend');
      
    },
    onClose() {
      this.$modal.hide('trial-extend');
    },
  },
};
</script>