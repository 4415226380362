<template>
  <div class="card" :class="{['card-recommended']: recommended}">
    <div class="card-header" @click="onSelect">
      <div v-if="recommended" class="recommend-banner">{{$t('Recommended')}}</div>
      <h4>{{name}}</h4>
      <div v-if="product && product.allowusertobuy" class="pricing">
        <div class="price" v-html="formatPrice(product.price / 12, $t('user/mo'))"></div>
        <div class="price-description">
          {{$tc('minimum {count} user | minimum {count} users', product.minimumquantitynew)}}, 
          {{$t('billed anually')}}
          <Icon
              v-tooltip="{
                content: $t(`Starting at ${formatInlinePrice(product.price * product.minimumquantitynew)} USD per year`),
              }"
              class="ml-1"
              name="info-circle"
              family="fas"
            />
        </div>
        <button class="btn btn-outline-primary btn-sm mt-3 mb-2">{{$t('Buy')}}</button>
      </div>    
      <div v-else class="pricing">
        <div class="price-description quote">{{$t('Custom pricing available')}}</div>
        <button class="btn btn-outline-primary btn-sm mt-3 mb-2">{{$t('Get Quote')}}</button>
      </div>
    </div>

    <div class="card-body">
      <ul class="d-none d-md-block">
        <li v-for="item in benefits.included" :key="item"><Icon name="check" family="far" /> <p>{{$t(item)}}</p></li>
        <li v-for="item in benefits.not_included" :key="item"><Icon name="times" class="text-danger" family="far" /> <p>{{$t(item)}}</p></li>
      </ul>
      <a class="btn btn-link" href="https://www.getfilecloud.com/pricing/" target="_blank">{{$t('More...')}}</a>
    </div>

  </div>
</template>
<script>

import Icon from 'common/components/Icon.vue';
import { formatPrice, formatInlinePrice } from '@/utils/checkout';

export default {
  components: {
    Icon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    product: {
      type: Object,
      default: () => {}
    },
    benefits: {
      type: Object,
      default: () => {}
    },
    recommended: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelect(e) {
      if(e.target.nodeName === 'A') {
        return;
      }
      this.$emit('select');
    },
    formatPrice,
    formatInlinePrice
  }
}
</script>
