<template>
  <label
    class="checkbox"
    :aria-label="label || description"
    :class="{ [`disabled`]: disabled }"
  >
    <span class="invisible">{{ label || description }}</span>
    <input
      :id="id"
      type="checkbox"
      :checked="trueValue ? (value || checked) : !(value || checked)"
      :name="name"
      :aria-label="label"
      :aria-description="description"
      :tabindex="tabindex"
      @input="onInput"
      @change="onChange"
      :true-value="trueValue"
      :false-value="falseValue"
    />
    <span class="checkmark">
      <Icon family="fal" name="check" />
    </span>
  </label>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    Icon,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, Number],
    },
    name: String,
    description: String,
    label: String,
    id: {
      type: String,
      default: '',
    },
    tabindex: {
      type: String,
      default: '0',
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.checked);
    },
    onChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
};
</script>
