<template>
  <div class="details-section">
    <Loader v-if="isLoading" loading full />

    <div class="modal-heading mb-2">
      <h2>{{ product.name }}</h2>
      <p>
        {{
          $t('Additional licenses for {site}', {
            site: currentProps && currentProps.site.deploymenturl,
          })
        }}
      </p>
    </div>

    <p class="modal-info mb-4">{{$t('Your license expires in {days} days, you will be charged proportionately', {days: currentProps.site.daysremaining})}}</p>

    <form @submit.prevent="onClickPrimary">
      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>

      <div class="row mb-2">
        <InputBox
          v-if="useInputForAmountOfUsers"
          id="quantity"
          v-model="form.quantity"
          :focus="true"
          :label="$t('Additional licenses')"
          type="number"
          :errors="errors"
          auto-select
        />
        <SlideInputBox
          v-else
          id="quantity"
          v-model="form.quantity"
          :label="$t('Additional licenses')"
          :hint="$t('Drag the slider below to change')"
          :range="{
            min: 0,
            max: 100,
          }"
          :step="1"
          :errors="errors"
        >
          <h4>
            {{ parseInt(form.quantity) }} <small>{{ $t('seats') }}</small>
          </h4>
        </SlideInputBox>
      </div>

      <div class="subtotal-section mt-1">
        <div class="total-description">
          <h4 class="mb-0">{{ $t('Additional Seats') }}</h4>
        </div>
        <strong class="pricing">{{ form.quantity }} {{ $t('Seats') }}</strong>
      </div>

      <div class="total-section mt-3">
        <div class="total-description">
          <h4>{{ $t('Total') }}</h4>
          <p>
            {{$t('Billed anually')}}
            <Icon
               v-tooltip="{
                 content: $t('For purchasing more than a year, please contact sales.'),
               }"
               class="ml-1"
               name="info-circle"
               family="fas"
             />
          </p>
        </div>
        <div v-if="!isNaN(totalPrice)" class="pricing">
          <div class="price" v-html="formatPrice(totalPrice)"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import InputBox from '@/components/InputBox';
import SlideInputBox from '@/components/SlideInputBox';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';
import dayjs from 'dayjs';

export default {
  name: 'Renew',
  components: {
    Loader,
    InputBox,
    SlideInputBox,
    Icon
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        quantity: 0,
      },
      useInputForAmountOfUsers: false,
      errors: {},
    };
  },
  computed: {
    totalPrice() {
      return (this.form.quantity * this.productRate);
    },
    product() {
      return this.$store.getters['checkout/productByLicenseType'](
        this.currentProps.site.licensetype
      );
    },
    isLoading() {
      return (
        this.$store.state.loading['checkout/renewLicense'] ||
        this.$store.state.loading['checkout/addLicenseSeats'] ||
        this.$store.state.loading['checkout/getProratedItemPrice']
      );
    },
    order() {
      return (
        this.$store.state.checkout.singleOrder[this.currentProps.orderNumber] ||
        {}
      );
    },
    isLicenceExpired() {
      return this.currentProps.site.daysremaining === 0;
    },
    expirityDate(){
      return dayjs(this.currentProps.site.expirydate).format('DD MMMM YYYY');
    },
    productRate(){
      return this.order.proratedPrice;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        if (form.quantity >= 100) {
          this.useInputForAmountOfUsers = true;
        }
      },
    },
  },
  mounted() {
        
    this.$store.dispatch('checkout/getProratedItemPrice', {
      licenseid: this.currentProps.site.id,
      itemnumber: this.product.number,
      ordernumber: this.currentProps.orderNumber,
    });

    this.form.quantity = this.product.minimumquantityupgrade ;

    this.$emit('settings', {
      primary: 'Checkout',
      secondary: this.currentProps.previousStep ? 'Back' : 'Cancel',
    });
  },
  methods: {
    async getSaleCartOrder() {
      // const order = await this.$store.dispatch('checkout/getSaleOrderCart');
      await this.$store.dispatch(
        'checkout/renewLicense',
        this.currentProps.site.id
      );
    },
    async onClickPrimary() {
      this.errors = {};

      if (
        this.form.quantity < this.product.minimumquantityupgrade
      ) {
        this.errors = {
          quantity: {
            message: this.$t(
              'The minimum amount of seats for this license is {minimum}',
              { minimum: this.product.minimumquantityupgrade }
            ),
          },
        };
        return;
      }

      const response = await this.$store.dispatch(
        'checkout/addLicenseSeats',
        {
          licenseId: this.currentProps.site.id,
          orderNumber: this.order.order_number,
          quantity: this.form.quantity,
          clearcart: true,
        }
      );

      if (!response.ok) {
        console.log(response);
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.errors = {};

      this.$emit('changeStep', {
        step: 'address',
        props: { orderNumber: this.order.order_number },
      });
    },
    onClickSecondary() {
      if (this.currentProps.previousStep) {
        this.$emit('changeStep', { step: this.currentProps.previousStep });
      } else {
        this.$modal.hide('checkout');
      }
    },
    formatInlinePrice,
    formatPrice,
  },
};
</script>
