export const onlineBenefits = {
  essentials: {
    included: [
      '1 TB. 100 GB per User',
      'Unlimited Licensed External Users',
      'Region-Specified, Dedicated Single-Tenant Hosting - Fully Isolated Data-Layer Protection',
    ],
    not_included: [
      'Sync/Backup Local File Server to Cloud',
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows ',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
      'Enable compliance with FIPS 140-2, ITAR, EAR, + Other Regulations',
    ]
  },
  advanced: {
    included: [
      '1 TB. 200 GB per User',
      'Unlimited Licensed External Users',
      'Region-Specified, Dedicated Single-Tenant Hosting - Fully Isolated Data-Layer Protection',
      'Sync/Backup Local File Server to Cloud',
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
    ],
    not_included: [
      'Enable compliance with FIPS 140-2, ITAR, EAR, + Other Regulations',
    ]
  },
  govcloud: {
    included: [
      '1 TB. 200 GB per User',
      'Unlimited Licensed External Users',
      'Region-Specified, Dedicated Single-Tenant Hosting - Fully Isolated Data-Layer Protection',
      'Sync/Backup Local File Server to Cloud',
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows ',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
      'Enable compliance with FIPS 140-2, ITAR,  EAR, + Other Regulations',
    ],
    not_included: []
  },
}

export const serverBenefits = {
  essentials: {
    included: [
      'Unlimited Storage',
      'Unlimited Licensed External Users',
      'Support for Network Shares with NTFS Integration for VPN-less secure access.',
    ],
    not_included: [
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows ',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
      'Optimized Multi-Site Architecture (high-performance, scalable and redundant data access in distributed data center environments)',
      'Enable compliance with FIPS 140-2, ITAR,  EAR, CJIS,  DoD Cloud Computing SRGs,  IRS-1075 + Other Regulations',
    ]
  },
  advanced: {
    included: [
      'Unlimited Storage',
      'Unlimited Licensed External Users',
      'Support for Network Shares with NTFS Integration for VPN-less secure access.',
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows ',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
      'Optimized Multi-Site Architecture (high-performance, scalable and redundant data access in distributed data center environments)',
      'Enable compliance with FIPS 140-2, ITAR,  EAR, CJIS,  DoD Cloud Computing SRGs,  IRS-1075 + Other Regulations',
    ],
    not_included: []
  },
  spla: {
    included: [
      'Unlimited Storage',
      'Unlimited Licensed External Users',
      'Support for Network Shares with NTFS Integration for VPN-less secure access.',
      'Content Classification & Search',
      'Content Lifecycle Management - Legal Hold, Retention, & Archival',
      'Unlimited Workflows ',
      'Digital Rights Management',
      'Key Integrations - MS Teams, Salesforce, Active Directory & SIEM',
      'Optimized Multi-Site Architecture (high-performance, scalable and redundant data access in distributed data center environments)',
    ],
    not_included: [
      'Enable compliance with FIPS 140-2, ITAR,  EAR, CJIS,  DoD Cloud Computing SRGs,  IRS-1075 + Other Regulations',
    ]
  },
}