import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'english', // set locale
  silentTranslationWarn: true, //disabled i18n warning
  fallbackLocale: 'english',
  formatFallbackMessages: true,
  messages: {
    // english: JSON.parse(localStorage.getItem('translations')),
  }, // set locale messages
});

// const isoCodes = {
//   arabic: 'ar',
//   chinese: 'zh',
//   dutch: 'nl',
//   english: 'en',
//   french: 'fr',
//   german: 'de',
//   italian: 'it',
//   portuguese: 'pt',
//   spanish: 'es',
// };
//
// const rtlLanguages = ['arabic'];
//
// const loadedLanguages = [localStorage.getItem('currentLang')]; // our default language that is preloaded
//
// function setI18nLanguage(lang) {
//   const useRtl = rtlLanguages.includes(lang);
//   store.dispatch('core/setUseRtl', useRtl);
//
//   i18n.locale = lang;
//
//   // insert VPAT compliant lang attribute on html tag
//   if (isoCodes[lang]) {
//     const html = document.documentElement;
//     html.setAttribute('lang', isoCodes[lang]);
//   }
//
//   return lang;
// }
//
// export function loadLanguageAsync(lang) {
//   // If the language was already loaded
//   if (localStorage.getItem('translations')) {
//     i18n.setLocaleMessage(
//       lang,
//       JSON.parse(localStorage.getItem('translations'))
//     );
//     return Promise.resolve(setI18nLanguage(lang));
//   }
//   // If the language hasn't been loaded yet
//   return import(
//     /* webpackChunkName: "lang-[request]" */ `common/lang/${lang}.json`
//   ).then((messages) => {
//     i18n.setLocaleMessage(lang, messages.default || messages);
//     loadedLanguages.push(lang);
//     localStorage.setItem('currentLang', lang);
//     localStorage.setItem('translations', JSON.stringify(messages));
//     return setI18nLanguage(lang);
//   });
// }
