import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueTelInput from 'vue-tel-input';
import VTooltip from 'v-tooltip'
import 'vue-tel-input/dist/vue-tel-input.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { isMobileDevice } from 'common/utils/responsive.js';

Vue.use(VTooltip)
Vue.use(VueTelInput, {defaultCountry: 'US', validCharactersOnly: true,showDialCodeInSelection: false});
Vue.use(VModal, { componentName: 'vue-modal' });

Vue.use(VueToast, {
    position: isMobileDevice() ? 'bottom' : 'top-right',
    duration: 5000,
    dismissible: true,
    queue: false,
});
