<template>
  <Modal
      name="renew-info"
      :title="$t('Contact us')"
      class="fixed-top renew-info-modal"
  >
    <div class="renew-info-section">
      <div class="row">
        <div class="col">
          <div class="renew-info-block">
            <div class="renew-info-block-header">
              <p>
                {{ $t('Please reach out to') }}
                <a href="mailto:sales@filecloud.com">{{ $t('sales@filecloud.com') }}</a>
                {{ $t('so that we can provide assistance.') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="row">
        <div class="col-auto ml-auto">
          <button class="btn btn-outline-primary px-5 ml-auto" @click="onClose">
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.renew-info-modal {
  top: 50px;
  .modal-body {
    min-height: auto;
  }
}
</style>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'RenewInfo',
  components: {
    Modal,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.$modal.hide('renew-info');
    },
  },
};
</script>
