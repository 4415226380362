export default [
  'Agriculture',
  'Apparel',
  'Architecture',
  'Automotive',
  'Biotechnology',
  'Chemicals',
  'Communications',
  'Construction',
  'Consulting',
  'DoD/Defense Industrial Base',
  'Education',
  'Electronics',
  'Energy/Oil & Gas',
  'Engineering',
  'Entertainment',
  'Environmental',
  'Financial Services/Banking',
  'Food & Beverage',
  'Government',
  'Healthcare/Life Sciences',
  'Hotels & Hospitality',
  'Information Technology/Software Services',
  'Insurance',
  'IT Managed Service Provider',
  'Machinery',
  'Manufacturing/Heavy Industry',
  'Marketing/Advertising',
  'Media/Publishing',
  'Mining',
  'Not For Profit',
  'Professional Services - Accounting/Tax/Legal',
  'Real Estate',
  'Recreation',
  'Retail/Wholesale/E-commerce',
  'Shipping/Logistics',
  'Telecommunications',
  'Transportation',
  'Utilities',
  'Aviation / Aerospace',
  'Defense / Space',
  'Financial / Banking',
  'Gas / Oil',
  'Government - Federal/City/State',
  'Legal',
  'Other'
];