export default [
  'Referral',
  'Online forum',
  'Google search',
  'Advertisement',
  'Trade show',
  'Referral / External File Share',
  'Existing Customer',
  'Web Search',
  'Other'
];