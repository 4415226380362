<template>
  <nav
    id="navbar"
    role="navigation"
    class="navbar navbar-main flex-md-nowrap fixed-top"
  >
    <div class="d-flex align-items-center">
      <button
        class="d-block d-lg-none mobile-menu-toggle pl-0"
        type="button"
        @click="toggleMobileSidebar"
      >
        <Icon family="fas" name="bars" />
      </button>

      <router-link class="navbar-brand" tabindex="-1" to="/">
        <Logo viewBox="0 0 250 60" width="125" height="30" />
      </router-link>
    </div>
    <ul v-if="user.customername" class="navbar-nav">
      <li class="nav-item">
        <VPopover ref="popover" popover-class="dropdown">
          <a class="nav-link nav-user" href="#">
            <Icon name="user" family="fas" class="mr-2" />
            <span class="d-none d-lg-inline">{{ user.customername }}</span>
            <Icon name="caret-down" family="fas" class="ml-2 caret" />
          </a>
          <template slot="popover">
            <div ref="dropdown" class="dropdown-wrapper">
              <div
                v-close-popover
                class="dropdown-item"
                tabindex="0"
                @click="showSettings"
              >
                <Icon name="user-cog" family="fal" />
                <span class="popover-text">
                  {{ $t('Settings') }}
                </span>
              </div>

              <div
                v-close-popover
                class="dropdown-item has-seperator"
                tabindex="0"
                @click="openSupport"
              >
                <Icon name="headset" family="fal" />
                <span class="popover-text">
                  {{ $t('Contact Us') }}
                </span>
              </div>

              <div
                class="dropdown-item"
                tabindex="0"
                @click="$store.dispatch('core/logout')"
              >
                <Icon name="sign-out-alt" family="fal" />
                <span class="popover-text">
                  {{ $t('Sign Out') }}
                </span>
              </div>
            </div>
          </template>
        </VPopover>
      </li>
    </ul>
  </nav>
</template>

<script>
import Logo from '@/assets/logo.svg';
import Icon from 'common/components/Icon';
import { VPopover } from 'v-tooltip';

export default {
  name: 'Navbar',
  components: {
    Logo,
    Icon,
    VPopover,
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    showMobileSidebar() {
      return this.$store.state.core.showMobileSidebar;
    },
  },
  methods: {
    toggleMobileSidebar() {
      this.$store.dispatch('core/showMobileSidebar', !this.showMobileSidebar);
    },
    showSettings() {
      this.$modal.show('profile');
    },
    openSupport() {
      this.$modal.show('contact-us');
    },
  },
};
</script>
