<template>
  <div>
    <div
      v-if="showMobileSidebar"
      class="mobile-sidebar-shadow"
      @click="closeMobileSidebar"
    />

    <div class="sidebar col px-0" :class="{ ['show']: showMobileSidebar }">
      <div>
        <div
          class="header"
          :class="{ ['open']: isUserMenuDropdownOpen }"
          @click="isUserMenuDropdownOpen = !isUserMenuDropdownOpen"
        >
          <div class="d-flex align-items-center">
            <Icon name="user" family="fas" class="mr-4" />

            <span>{{ user.customername }}</span>

            <Icon
              name="caret-down"
              family="fas"
              class="user-dropdown-chevron ml-4"
            />
          </div>

          <ul>
            <li v-for="(action, index) in userActions" :key="index">
              <a :href="action.url">
                <Icon family="fal" :name="action.icon" />
                {{ action.label }}
              </a>
            </li>
          </ul>
        </div>

        <ul class="sidebar-nav">
          <li
            :class="{ active: $route.name === 'dashboard' }"
            @click="closeMobileSidebar"
          >
            <router-link to="/">
              <Icon name="th-large" family="fal" />
              {{ $t('Dashboard') }}
            </router-link>
          </li>
          <li :class="{ active: hasSiteActive }">
            <a @click="showSites = !showSites">
              <Icon name="list-ul" family="fal" />

              {{ $t('Sites') }}

              <Icon
                v-if="!showSites"
                name="caret-down"
                class="caret"
                family="fas"
              />

              <Icon v-else name="caret-up" class="caret" family="fas" />
            </a>

            <transition name="fade">
              <ul v-if="showSites" class="site-list">
                <li v-if="showViewAllSitesOption">
                  <InputBox
                    v-model="query"
                    :placeholder="$t('Search for sites...')"
                    rounded
                    form-size="sm"
                    class="px-0 mb-0"
                  />
                </li>
                <li
                  v-for="(site, index) in sites"
                  :key="index"
                  @click="closeMobileSidebar"
                >
                  <router-link :to="getCardLink(site)">
                    <h6>
                      <b>{{ getSiteName(site, true) }}</b>
                      <span v-if="site.istrial && !site.iscommunity" class="is-trial-badge">{{
                        $t('trial')
                      }}</span>
                      <span
                        v-else-if="site.ispending"
                        class="is-pending-badge"
                        >{{ $t('pending') }}</span
                      >
                      <span
                        v-else-if="site.daysremaining === 0"
                        class="is-expired-badge"
                      />
                    </h6>
                    <span v-if="!site.isExpired && site.deploymenturl">{{
                      site.deploymenturl
                    }}</span>
                    <span v-else-if="site.daysremaining === 0">{{
                      $t('The license is expired')
                    }}</span>
                  </router-link>
                </li>

                <li v-if="showViewAllSitesOption && !query" class="view-more">
                  <a @click="viewAllSites = !viewAllSites">
                    {{ $t(viewAllSites ? 'View Less' : 'View All') }}
                    <Icon
                      class="pl-2"
                      family="fas"
                      :name="viewAllSites ? 'chevron-up' : 'chevron-down'"
                    />
                  </a>
                </li>

                <li v-else class="add-new-site">
                  <a @click="$modal.show('checkout')">
                    <Icon family="fal" name="plus" />
                    {{ $t('Add new site...') }}
                  </a>
                </li>
              </ul>
            </transition>
          </li>
          <li v-if="showDownloadSection"
            :class="{ active: $route.name === 'downloads' }"
            @click="closeMobileSidebar"
          >
            <router-link to="/downloads">
              <Icon name="download" family="fal" />
              {{ $t('Downloads') }}
            </router-link>
          </li>
          <li
            v-if="user.isspla && user.issplaenabled"
            :class="{ active: $route.name === 'spla' }"
            @click="closeMobileSidebar"
          >
            <router-link to="/spla">
              <Icon name="signal" family="fal" />
              {{ $t('SPLA Reports') }}
            </router-link>
          </li>
          <li
            :class="{ active: $route.name === 'billing' }"
            @click="closeMobileSidebar"
          >
            <router-link to="/billing">
              <Icon name="receipt" family="fal" />
              {{ $t('Billing') }}
              <span
                v-if="hasQuoteOrUnpaidBill"
                class="bg-danger rounded-circle d-inline-block ml-2"
                style="width: 10px; height: 10px"
              />
            </router-link>
          </li>
        </ul>
      </div>

      <footer>
        <SupportBlock />
        <small v-if="showVersion" @click="toggleVersionDisplay">Web UI v{{version}}</small>
        <small v-else @click="toggleVersionDisplay">FileCloud &copy; {{ currentYear }}</small>
      </footer>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import InputBox from '../InputBox.vue';
import SupportBlock from '@/components/Partials/SupportBlock';

import { getSiteName, getCardLink } from '../../utils/licenses';
import appPackage from '@/../package.json';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';

export default {
  name: 'Sidebar',
  components: {
    Icon,
    SupportBlock,
    InputBox,
  },
  data() {
    return {
      isUserMenuDropdownOpen: false,
      showSites: false,
      userActions: [
        { icon: 'user-cog', label: 'Settings', url: '#' },
        { icon: 'headset', label: 'Support', url: '#' },
        { icon: 'sign-out-alt', label: 'Sign Out', url: '#' },
      ],
      viewAllSites: false,
      query: '',
      version: appPackage.version,
      showVersion: false
    };
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    showDownloadSection() {
      return this.$store.getters['core/showDownloadSection'];
    },
    hasSiteActive() {
      return this.$route.name === 'site';
    },
    hasQuoteOrUnpaidBill() {
      return this.$store.state.core.orders.some(
        (order) =>
          this.getOrderStatus(order) !== 'paid' &&
          this.getOrderStatus(order) !== 'delivered'
      );
    },
    currentYear() {
      return new Date().getFullYear();
    },
    showMobileSidebar() {
      return this.$store.state.core.showMobileSidebar;
    },
    isTabletOrLess() {
      return window.innerWidth < 992;
    },
    sites() {
      const sites = this.$store.state.sites.licenses
        .filter((license) => {
          return !license.ispending;
        })
        .map((site) => {
          return {
            ...site,
            _name: this.getSiteName(site, true),
            deploymenturl:
              typeof site.deploymenturl === 'string' ? site.deploymenturl : '',
            _meta: `${site.istrial ? this.$t('trial') : ''} ${
              site.daysremaining <= 0 ? this.$t('expired') : ''
            } ${
              site.licensegroup === 'Online'
                ? this.$t('online')
                : this.$t('server')
            } ${site.iscommunity ? this.$t('community') : ''}`,
          };
        });

      if (this.viewAllSites || this.query) {
        if (!this.query) return sites;

        const fuse = new Fuse(sites, {
          keys: ['deploymenturl', '_name', '_meta'],
          distance: 200,
          threshold: 0.3,
        });

        return fuse.search(this.query).map((sites) => sites.item);
      }
      return sites.slice(0, 4);
    },
    showViewAllSitesOption() {
      return (
        this.$store.state.sites.licenses.filter((license) => {
          return !license.ispending;
        }).length > 4
      );
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.name === 'siteTrial' || route.name === 'site')
          this.showSites = true;
      },
    },
  },
  methods: {
    getSiteName,
    closeMobileSidebar() {
      this.showMobileSidebar &&
        this.$store.dispatch('core/showMobileSidebar', false);

      this.isUserMenuDropdownOpen && (this.isUserMenuDropdownOpen = false);
    },
    getCardLink,
    getOrderStatus(order) {
      const today = dayjs();
      const expirationDate = dayjs(order.order_expiration_date);
      // const expirationDate = dayjs(order);

      if (!expirationDate.isToday() && today.diff(expirationDate) >= 0) {
        return 'expired';
      } else if (order.order_delivered == 1) {
        return 'delivered';
      } else if (order.fullpaymentreceived == 1) {
        return 'paid';
      } else if (order.order_status == 3) {
        return 'invoice';
      }
      return 'quote';
    },
    toggleVersionDisplay() {
      this.showVersion = !this.showVersion;
    }
  },
};
</script>

<style lang="scss" scoped>
ul.site-list {
  padding-left: 1.875rem;
  list-style: none;

  li {
    padding: 0.625rem 0;

    &.add-new-site,
    &.view-more {
      display: flex;
      align-items: center;
      font-size: 12.4px;

      a {
        font-weight: 500 !important;
        flex-direction: row;
        align-items: center;
      }

      i {
        color: var(--text-muted) !important;
        margin-right: 4px;
      }

      span {
        color: var(--color-primary);
      }
    }

    a {
      display: block;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: column;

      &.router-link-active {
        h6 {
          color: var(--color-primary);
        }
      }

      h6 {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 0px;
        color: var(--text-medium);
        font-weight: bold;
        b {
          font-weight: bold;
          max-width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span {
        font-size: 12.4px;
        color: var(--text-light);
        font-weight: 500 !important;
        text-overflow: inherit;
        white-space: nowrap;
        overflow: hidden;
      }

      span.is-trial-badge,
      .is-pending-badge {
        font-size: 10px;
        color: var(--color-primary--foreground);
        font-weight: 500;
        background: var(--color-primary);
        // width: 31px;
        height: 10px;
        padding: 7px 10px;
        border-radius: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
      }

      span.is-expired-badge {
        background: var(--red);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 8px;
      }
    }
  }
}
</style>
