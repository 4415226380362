<template>
  <Modal
    name="start-free-trial"
    :title="$t('Start your FileCloud Server Trial')"
    class="fixed-top"
    no-close
  >
    <div class="start-free-trial">
      <Loader :loading="isLoading" full />

      <div class="hello-world">
        <HelloWorldImage />
      </div>

      <div class="modal-content">
        <div v-if="errors._message" class="error-message">
          <Icon family="fas" name="times" />
          <span>{{ errors._message }}</span>
        </div>

        <div class="modal-content__welcome">
          <h3>{{ $t('Welcome to FileCloud') }}</h3>

          <p>
            {{
              $t(
                "Thank you for signing up for FileCloud's Server Trial - hosted on your own infrastructure."
              )
            }}
          </p>

          <p>{{ $t('Please click the button below to get started.') }}</p>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>

        <div class="col-auto">
          <button class="btn btn-primary px-5 ml-auto" @click="onSubmit">
            {{ $t('Start trial') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';
import HelloWorldImage from '@/assets/hello-world.svg';

export default {
  name: 'StartFreeTrial',
  components: {
    Loader,
    Modal,
    SupportBlock,
    HelloWorldImage,
  },
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['sites/startFreeTrial'];
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  methods: {
    async onSubmit() {
      const trialResponse = await this.$store.dispatch('sites/startFreeTrial', {
        trialtype: 0,
      });

      if (!trialResponse.ok) {
        this.errors =
          typeof trialResponse.error === 'string'
            ? { _message: trialResponse.error }
            : trialResponse.error;
        return;
      }

      this.$router.push('/sites/trial/server?getstarted=true');
      this.$modal.hide('start-free-trial');
    },
  },
};
</script>

<style lang="scss" scoped>
.start-free-trial {
  margin-inline: -2rem;
  margin-top: calc(-2rem - 1px);
  .hello-world {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    background: #cfd5e2;
    padding-inline: 1rem;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    border: none;
    padding-inline: 1rem;
    margin-top: 2rem;

    &__welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      h3,
      p {
        width: 340px;
        max-width: 100%;
        text-align: center;
        margin: 0;
      }

      h3 {
        color: var(--text-dark);
        font-size: 16px;
        font-weight: 500;
      }

      p {
        color: var(--text-light);
      }
    }
  }
}
</style>
