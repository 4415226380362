<template>
  <Modal
    name="profile"
    :title="
      $t(
        profileComplete || user.iscommunityedition
          ? 'Settings'
          : 'Let us know more about you'
      )
    "
    class="fixed-top"
    :no-close="!profileComplete"
    @before-open="onOpen"
  >
    <div class="profile">
      <Loader :loading="isLoading" full />

      <div
        v-if="profileComplete || user.iscommunityedition"
        class="modal-heading profile-complete"
      >
        <Icon family="fas" name="user" />
        <div class="modal-heading-content">
          <h5>{{ user.customername }}</h5>
          <span>{{ user.email }}</span>
        </div>
      </div>

      <div v-else class="modal-heading">
        <h2 v-if="user.iscommunityedition">
          {{ $t('Start your FileCloud Community Edition') }}
        </h2>
        <h2 v-else-if="!isOnlineTrial">
          {{ $t('Start your FileCloud Server Trial') }}
        </h2>
        <h2 v-else>{{ $t('Complete your FileCloud profile') }}</h2>
        <p>{{ $t('In order for us to better serve you, please tell us more about your environment') }}</p>
      </div>

      <div
        v-if="user.profilecomplete || user.iscommunityedition"
        class="tab-headers-wrapper"
      >
        <div
          class="tab-heading"
          :class="{ ['active']: activeTab === 'GENERAL' }"
          :aria-selected="activeTab === 'GENERAL' ? true : false"
          @click="setActiveTab('GENERAL')"
        >
          {{ $t('General') }}
        </div>
        <div
          class="tab-heading"
          :class="{ ['active']: activeTab === 'PASSWORD' }"
          :aria-selected="activeTab === 'PASSWORD' ? true : false"
          @click="setActiveTab('PASSWORD')"
        >
          {{ $t('Password') }}
        </div>
      </div>

      <div v-if="errors._message" class="error-message">
        <Icon family="fas" name="times" />
        <span>{{ errors._message }}</span>
      </div>

      <form v-if="activeTab === 'GENERAL'" @submit.prevent="onSubmit">
        <div class="row">
          <SelectBox
            v-if="!form.country || profileComplete || errors['country']"
            id="country"
            v-model="form.country"
            class="col-md-6"
            :focus="true"
            :label="$t('Country')"
            :placeholder="$t('Select...')"
            :errors="errors"
          >
            <option
              v-for="country in countries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </SelectBox>

          <InputBox
            v-if="user.iscommunityedition"
            id="company"
            v-model="form.company"
            :disabled="hasCompany"
            :label="$t('Company')"
            type="text"
            class="col-md-6"
            :errors="errors"
          />

          <PhoneInputBox
            v-if="
              !user.iscommunityedition &&
              (!form.phone || profileComplete || errors['phone'])
            "
            id="phone"
            ref="phone"
            v-model="form.phone"
            :label="$t('Phone Number')"
            class="col-md-6"
            :errors="errors"
          />
        </div>
        <!-- Comment out these blocks
        <div v-if="!user.iscommunityedition" class="row">
          <SelectBox
            id="purchasetimeframe"
            v-model="form.purchasetimeframe"
            :label="$t('Purchase Time Frame')"
            :placeholder="$t('Select...')"
            :errors="errors"
          >
            <option v-for="item in purchasetimeframe" :key="item" :value="item">
              {{ item }}
            </option>
          </SelectBox>

          <SelectBox
            id="industry"
            v-model="form.industry"
            :label="$t('Industry')"
            :placeholder="$t('Select...')"
            :errors="errors"
          >
            <option
              v-for="industry in industries"
              :key="industry"
              :value="industry"
            >
              {{ industry }}
            </option>
          </SelectBox>
        </div> -->

        <!-- <div class="row">
          <InputBox
            id="department"
            v-model="form.department"
            :label="$t('Department')"
            :errors="errors"
            optional
          />
          <InputBox
            id="role"
            v-model="form.role"
            :label="$t('Role')"
            :errors="errors"
            optional
          />
        </div> -->
         
        <!-- Comment out these blocks
        <div v-if="!user.iscommunityedition" class="row">
          <SelectBox
            id="licensecount"
            v-model="form.licensecount"
            :label="$t('Number of Users')"
            :placeholder="$t('Select...')"
            :errors="errors"
            optional
          >
            <option v-for="item in licenseamounts" :key="item" :value="item">
              {{ item }}
            </option>
          </SelectBox>
          <SelectBox
            id="heardvia"
            v-model="form.heardvia"
            :label="$t('How did you hear about us?')"
            :placeholder="$t('Select...')"
            :errors="errors"
            optional
          >
            <option v-for="item in heardvia" :key="item" :value="item">
              {{ item }}
            </option>
          </SelectBox>
        </div>
      -->

        <!-- <div v-if="!profileComplete" class="row">
          <InputBox
            id="password"
            v-model="form.password"
            class="mb-0"
            :label="$t('Set an account password')"
            type="password"
            :errors="errors"
            optional
          />
        </div> -->

        <button type="submit" class="d-none">test</button>
      </form>

      <form
        v-else-if="activeTab === 'PASSWORD'"
        @submit.prevent="onSubmitPassword"
      >
        <div v-if="user.haspassword" class="row">
          <InputBox
            id="oldpassword"
            v-model="form.oldpassword"
            class="col-12 col-md-6"
            type="password"
            :label="$t('Old Password')"
            :errors="errors"
          />
        </div>

        <div class="row">
          <InputBox
            id="newpassword"
            v-model="form.newpassword"
            type="password"
            :label="
              $t(user.haspassword ? 'New Password' : 'Set an account password')
            "
            :errors="errors"
          />
          <InputBox
            id="newpasswordconfirm"
            v-model="form.newpasswordconfirm"
            type="password"
            :label="$t('Confirm New Password')"
            :errors="errors"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button
            v-if="!profileComplete && !isOnlineTrial"
            class="btn btn-primary px-5 ml-auto"
            @click="onSubmit"
          >
            {{ $t('Start trial') }}
          </button>
          <button
            v-else-if="profileComplete && activeTab === 'PASSWORD'"
            class="btn btn-primary px-5 ml-auto"
            @click="onSubmitPassword"
          >
            {{ $t('Update password') }}
          </button>
          <button v-else class="btn btn-primary px-5 ml-auto" @click="onSubmit">
            {{ $t('Save') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Icon from 'common/components/Icon.vue';
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';
import InputBox from '@/components/InputBox.vue';
import PhoneInputBox from '@/components/PhoneInputBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import countries from '@/constants/salesforce-countries';
import industries from '@/constants/industries';
import purchasetimeframe from '@/constants/purchasetimeframe';
import licenseamounts from '@/constants/licenseamounts';
import heardvia from '@/constants/heardvia';

export default {
  name: 'Profile',
  components: {
    Icon,
    Loader,
    Modal,
    SupportBlock,
    InputBox,
    SelectBox,
    PhoneInputBox,
  },
  data() {
    return {
      form: {},
      errors: {},
      hasCompany: false,
      countries: Object.values(countries),
      industries,
      purchasetimeframe,
      licenseamounts,
      heardvia,
      activeTab: 'GENERAL',
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.loading['core/updateProfile'] ||
        this.$store.state.loading['core/updatePassword'] ||
        this.$store.state.loading['sites/startFreeTrial']
      );
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
    profileComplete() {
      return this.user.profilecomplete == 1;
    },
    isOnlineTrial() {
      return this.user.producttype === 1;
    },
  },
  methods: {
    async onSubmitPassword() {
      const response = await this.$store.dispatch(
        'core/updatePassword',
        this.form
      );

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.errors = {};

      this.$modal.hide('profile');
    },
    async onSubmit() {
      this.errors = {};
      
      const response = await this.$store.dispatch('core/updateProfile', {
        ...this.form,
        iscommunityedition: this.user.iscommunityedition,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }


      // prompt trial
      /* if (this.user.istrialeligible === 1 && !this.isOnlineTrial) {
        const trialResponse = await this.$store.dispatch(
          'sites/startFreeTrial',
          {
            trialtype: this.user.iscommunityedition ? 1 : 0,
          }
        );

        if (!trialResponse.ok) {
          this.errors =
            typeof trialResponse.error === 'string'
              ? { _message: trialResponse.error }
              : trialResponse.error;
          return;
        }

        this.$router.push(
          `/sites/trial/${
            this.user.iscommunityedition ? 'community' : 'server'
          }?getstarted=true`
        );
      } */

      this.$modal.hide('profile');
    },
    onOpen() {
      const {
        country,
        company,
        phone,
        industry,
        department,
        purchasetimeframe,
        role,
        licensecount,
        heardvia,
      } = this.user;

      // pre fill
      this.form = {
        country,
        company: typeof company === 'string' ? company : '',
        industry: typeof industry === 'string' ? industry : '',
        purchasetimeframe:
          typeof purchasetimeframe === 'string' ? purchasetimeframe : '',
        phone:
          typeof phone === 'string' || typeof phone === 'number'
            ? `${phone}`
            : '',
        department: typeof department === 'string' ? department : '',
        role: typeof role === 'string' ? role : '',
        licensecount: typeof licensecount === 'string' ? licensecount : '',
        heardvia: typeof heardvia === 'string' ? heardvia : '',
      };

      this.hasCompany = !!this.form.company;

      this.errors = {};
      this.activeTab = 'GENERAL';
    },
    setActiveTab(tab) {
      this.errors = {};
      this.activeTab = tab;
    },
  },
};
</script>
