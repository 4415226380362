<template>
  <div class="welcome-section">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="card p-3" @click="onSelect('online')">
          <div class="row no-gutters align-items-center">
            <Clouds class="col-4"/>
            <div class="col-8 d-flex flex-column">
              <h4>{{$t('Enterprise Online')}}</h4>
              <span>{{$t('Run FileCloud on our secure infrastructure')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mt-3 mt-sm-0">
        <div class="card p-3" @click="onSelect('onprem')">
          <div class="row no-gutters align-items-center">
            <Warehouse class="col-4"/>
            <div class="col-8 d-flex flex-column">
              <h4>{{$t('Enterprise Server')}}</h4>
              <span>{{$t('Run FileCloud on your own infrastructure')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Clouds from '@/assets/clouds.svg';
import Warehouse from '@/assets/warehouse.svg'

export default {
  name: 'Welcome',
  components: {
    Clouds,
    Warehouse,
  },
  mounted() {
    this.$emit('settings', {
      secondary: 'Cancel'
    })
  },
  methods: {
    onSelect(type, section = null) {
      this.$emit('changeStep', { step: (section) ? section : 'pricing', props: { group: type } });
    },
    onClickSecondary() {
      this.$modal.hide('checkout');
    }
  }
};
</script>
