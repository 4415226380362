export const getSiteName = (license, raw = false) => {
    if (license.deploymenturl && typeof license.deploymenturl === 'string') {
        const [siteName, ...extension] = license.deploymenturl.split('.');

        if (raw) return siteName;
        return `${siteName}<small>.${extension.join('.')}</small>`;
    } else if (license.istrial) {
        return license.licensegroup === 'Online'
            ? 'Online Trial'
            : license.licensegroup === 'Community'
                ? 'FileCloud Community Edition'
                : 'Server Trial';
    } else if (license.spla) {
        return 'SPLA';
    } else {
        return license.licensegroup;
    }
}

export const getCardLink = (license) => {
    if (!license.istrial && !license.ispending) {
        return `/sites/${license.id}`;
    } else if (license.istrial) {
        return `/sites/trial/${typeof license.id === 'string'
                ? license.id
                : license.licensegroup === 'Online'
                    ? 'online'
                    : license.licensegroup === 'Community'
                        ? 'community'
                        : 'server'
            }`;
    } else if (license.ispending) {
        return '/';
    }
}