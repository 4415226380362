import axios from 'axios';
import qs from 'querystring';
import convert from 'xml-js';
import upcast from 'upcast';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

import Validator from 'fastest-validator';
import schemas from './schemas';
import responseSchemas from './response-schemas';
import serverURL from '../properties/serverURL';
import { readCookie, readTextFromBlobAsync } from '../../utils/files';
import { encodeAll } from '../../utils/URLUtils';
import _ from 'lodash';
import { HTTP, blobErrorPattern } from '../../utils/constants';

class FileCloud {
  constructor(userType) {
    this.url = serverURL.domainURL; // default rest url
    this.schemas = schemas;
    this.responseSchemas = responseSchemas;
    const XsrfCookies = [
      'X-XSRF-TOKEN-user',
      'X-XSRF-TOKEN-share',
      'X-XSRF-TOKEN-admin',
      'X-XSRF-TOKEN-superadmin',
    ];
    let xsrfToken = 'NONE';

    // schema validator
    this.validator = new Validator({
      messages: {
        stringEmpty: 'This field must not be empty.',
        /*
        required: "general.errors.required",
        email: "general.errors.invalid-email",
        stringMin: "general.errors.string-min",
        */
      },
    });
    let tokenName = `X-XSRF-TOKEN-${userType}`;
    // base axios config
    this.config = {
      validateStatus: false,
      withCredentials: true,
      xsrfCookieName: tokenName,
      xsrfHeaderName: tokenName,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        'X-Requested-With': 'XMLHttpRequest',
      },
    };

    for (let i = 0; i < XsrfCookies.length; i++) {
      xsrfToken = 'NONE';
      if (readCookie(XsrfCookies[i]) != null && readCookie(XsrfCookies[i])) {
        xsrfToken = readCookie(XsrfCookies[i]);
      }
      if (xsrfToken != 'NONE') {
        this.config.headers[XsrfCookies[i]] = xsrfToken;
      }
    }
    if (xsrfToken == 'NONE') {
      // required for HttpOnly cookies
      this.config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }
  }

  setXsrfToken() {
    const XsrfCookies = [
      'X-XSRF-TOKEN-user',
      'X-XSRF-TOKEN-share',
      'X-XSRF-TOKEN-admin',
      'X-XSRF-TOKEN-superadmin',
    ];
    let xsrfToken = 'NONE';


    XsrfCookies.forEach((cookie) => {
      xsrfToken = 'NONE';
      if (readCookie(cookie) != null && readCookie(cookie)) {
        xsrfToken = readCookie(cookie);
      }
      if (xsrfToken != 'NONE') {
        this.config.headers[cookie] = xsrfToken;
      }
    })
    if (xsrfToken == 'NONE') {
      // required for HttpOnly cookies
      this.config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }

  }

  async timeoutSession(store) {
    axios.interceptors.response.use(
      function (response) {
        if (response.status === 401) {
          store.dispatch('auth/logout');
        }
        return response;
      },
      function (error) {
        if (axios.isCancel(error)) {
          // Transaction Cancelled
          return Promise.reject({
            ...error,
            code: 'CANCELLED',
          });
        } else if (error.isAxiosError || !error.response) {
          // Network Error
          // @see https://github.com/axios/axios/pull/3645/files#
          return Promise.reject({
            ...error,
            code: 'NETWORK_ERROR',
          });
        } else {
          if (error.response.data.error.statusCode === 401) {
            store.dispatch('auth/logout');
          }
          return Promise.reject(error);
        }
      }
    );
  }

  async setEncryptionHeader(value) {
    this.config.headers['Encryption-Header'] = value;
  }

  async removeEncryptionHeader() {
    delete this.config.headers['Encryption-Header'];
  }

  // makes a GET request
  async get(endpoint, params = {}, isJson = '') {
    this.config.headers['Accept'] =
      isJson === HTTP.USE_JSON
        ? 'application/json'
        : 'application/x-www-form-urlencoded';

    return this.request('GET', endpoint, null, params);
  }

  async getUrl(endpoint, params = {}) {
    return `${this.url}/${endpoint}?${qs.stringify(params)}`;
  }

  async getUnencoded(endpoint, params = {}) {
    try {
      this.config.headers['Accept'] = 'application/x-www-form-urlencoded';
      const response = await axios.request({
        method: 'GET',
        url: `${this.url}/${endpoint}`,
        params: params,
        paramsSerializer: function (params) {
          return Object.keys(params)
            .map((key) => key + '=' + encodeAll(params[key]))
            .join('&');
        },
        ...this.config,
      });

      const responseData = this.parseResponse(response.data, endpoint);

      // if it's a command
      if (responseData.commands) {
        const { command } = responseData.commands;

        return {
          ok: command.result === 1,
          data: command,
          error: command.message,
        };
      }

      if (response.status !== 200) {
        throw new Error(response.data);
      }

      return {
        ok: true,
        data: responseData[Object.keys(responseData)[0]], // remove first level
      };
    } catch (e) {
      return {
        ok: false,
        data: {
          message: e.message,
        },
      };
    }
  }

  // makes a GET request, and dont parse the result
  async getPlain(endpoint, params = {}) {
    return this.request('GET', endpoint, null, params, {}, true);
  }

  async postPlain(endpoint, params = {}) {
    return this.request('POST', endpoint, null, params, {}, true);
  }

  // makes a GET request, and return a blob
  async getBlob(
    endpoint,
    params = {},
    onDownloadProgress = null,
    method = 'GET'
  ) {
    const source = axios.CancelToken.source();

    // request to external api
    const response = await axios.request({
      method,
      url: `${this.url}/${endpoint}`,
      responseType: 'blob',
      params: params,
      onDownloadProgress: (e) => {
        if (onDownloadProgress) onDownloadProgress(e, source);
      },
      cancelToken: source.token,
      ...this.config,
    });

    if (response.status >= 200 && response.status < 400) {
      return {
        ok: true,
        data: response.data,
      };
    } else {
      let error;
      if (response.status === 403) {
        error = await response.data.text(); //Forbidden - DLP error
      } else {
        error = await this.parseErrorMessageFromBlob(response.data);
      }

      if (!error) {
        error = 'Something went wrong with file download';
      }

      return {
        ok: false,
        data: response.data,
        error,
      };
    }
  }

  validate(endpoint, data = {}) {
    const isValid = this.schemas[endpoint]
      ? this.validator.validate(data, this.schemas[endpoint])
      : true;

    if (isValid === true) {
      return { ok: true };
    } else {
      return {
        ok: false,
        error: this.mapErrors(isValid),
      };
    }
  }

  // makes a POST request
  async post(endpoint, data = {}, params = {}, isJson = '') {
    // validate fields

    const isValid = this.schemas[endpoint]
      ? this.validator.validate(data, this.schemas[endpoint])
      : true;

    this.config.headers['Accept'] =
      isJson === HTTP.USE_JSON
        ? 'application/json'
        : 'application/x-www-form-urlencoded';

    this.config.headers['Content-Type'] =
      isJson === HTTP.USE_JSON
        ? 'application/json'
        : 'application/x-www-form-urlencoded';

    if (isValid === true) {
      return this.request(
        'POST',
        endpoint,
        isJson ? JSON.stringify(data) : qs.stringify(data),
        {
          ...params,
          time: dayjs().unix(),
        }
      );
    } else {
      return {
        ok: false,
        error: this.mapErrors(isValid),
      };
    }
  }

  async postBlobWithStreamedJSONResponse(
    endpoint,
    params = {},
    onData = () => {}
  ) {
    const formData = new FormData();

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    const headers = {
      ...this.config.headers,
      Accept: 'application/octet-stream',
    };
    delete headers['Content-Type'];

    try {
      const response = await fetch(`${this.url}/${endpoint}`, {
        method: 'POST',
        body: formData,
        headers,
      });

      const reader = response.body?.getReader();

      if (!reader) return;

      const decoder = new TextDecoder('utf-8');

      let data = '';

      while (true) {
        const readResult = await reader.read();
        data =
          readResult.value !== undefined
            ? decoder.decode(readResult.value, { stream: true })
            : '';
        const jsonSegments = data.split('\n');

        const responseData = this.parseResponse(jsonSegments[0], endpoint);

        // if it's a command
        if (responseData.commands) {
          const { command } = responseData.commands;
          const response = {
            ok: command.result === 1,
            data: command,
            error: command.message,
          };

          if (!response.ok) {
            throw response;
          }
        } else {
          jsonSegments.forEach((segment) => {
            if (segment) onData(JSON.parse(segment));
          });
        }

        if (readResult.done) {
          break;
        }
      }
    } catch (error) {
      return error;
    }
  }

  async postBlob(endpoint, params = {}, onDownloadProgress = null) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    const source = axios.CancelToken.source();

    // request to external api
    const response = await axios.request({
      method: 'POST',
      url: `${this.url}/${endpoint}`,
      responseType: 'blob',
      data: formData,
      onDownloadProgress: (e) => {
        if (onDownloadProgress) onDownloadProgress(e, source);
      },
      cancelToken: source.token,
      ...this.config,
    });

    // if it's a blob
    if (response.status === 200) {
      return {
        ok: true,
        data: response.data,
        headers: response.headers,
        status: response.status,
      };
    } else {
      // converts blob to text
      const responseText = await readTextFromBlobAsync(response.data);
      const responseData = this.parseResponse(responseText, endpoint);

      // if it's a command
      if (responseData && responseData.commands) {
        const { command } = responseData.commands;

        return {
          ok: command.result === 1,
          data: command,
          error: command.message,
        };
      } else {
        return {
          ok: false,
          error: responseText,
        };
      }
    }
  }

  async postXML(endpoint, data, params = {}, onUploadProgress = () => {}) {
    const source = axios.CancelToken.source();

    return await this.request(
      'POST',
      endpoint,
      data,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        onUploadProgress: (e) => {
          onUploadProgress(e, source);
        },
        cancelToken: source.token,
      },
      true
    );
  }

  async postMultipart(
    endpoint,
    data,
    params = {},
    onUploadProgress = () => {},
    encryptedPass = '',
    config = {}
  ) {
    // parse multipart
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    if (encryptedPass !== '') {
      headers['Encryption-Header'] = encryptedPass;
    }

    headers = { ...headers, ...this.config.headers };

    if (config.zipViewer && this.config.headers['X-XSRF-TOKEN'] === 'NONE') {
      headers['X-XSRF-TOKEN'] = 'NONE';
    }

    const source = axios.CancelToken.source();

    return await this.request(
      'POST',
      endpoint,
      formData,
      params,
      {
        headers,
        onUploadProgress: (e) => {
          onUploadProgress(e, source);
        },
        cancelToken: source.token,
      },
      true
    );
  }

  async request(
    method,
    endpoint,
    data,
    params = {},
    config = {},
    isPlain = false
  ) {
    try {
      // request to external api
      const response = await axios.request({
        method,
        url: `${this.url}/${endpoint}`,
        data: data,
        params: params,
        ...this.config,
        ...config,
      });

      if (response.headers['content-type'] === 'application/json') {
        return response;
      }

      if (response.headers['content-type'] == 'application/csv;charset=UTF-8') {
        return response;
      }

      const responseData = isPlain
        ? response.data
        : this.parseResponse(response.data, endpoint);

      // if it's a command
      if (responseData.commands) {
        const { command } = responseData.commands;

        return {
          ok: command.result === 1,
          data: command,
          error: command.message,
        };
      }

      if (response.status !== 200) {
        throw new Error(response.data);
      }

      return {
        ok: true,
        data: isPlain
          ? responseData
          : responseData[Object.keys(responseData)[0]], // remove first level
      };
    } catch (e) {
      return {
        ok: false,
        data: {
          code: e?.code,
          message: e.message,
        },
      };
    }
  }

  setXsrfCookieName(userType) {
    const tokenName = `X-XSRF-TOKEN-${userType}`;

    // base axios config
    this.config.xsrfCookieName = tokenName;
    this.config.xsrfHeaderName = tokenName;
  }

  // map validation errors
  mapErrors(errors) {
    let response = {};
    errors.forEach((error) => {
      const { actual, expected, field, message } = error;
      response[error.field] = {
        message,
        meta: {
          actual,
          expected,
          field,
        },
      };
    });
    return response;
  }

  parseResponse(xml, endpoint) {
    const responseSchema =
      endpoint && this.responseSchemas ? this.responseSchemas[endpoint] : null;

    try {
      // parse native type
      const nativeType = function (value, keyName, parentPath) {
        // if there's a response schema, use that as a native type parser
        if (responseSchema && responseSchema[`${parentPath}.${keyName}`]) {
          const { type } = responseSchema[`${parentPath}.${keyName}`];

          // cast type
          if (type) {
            return upcast.to(value, type);
          }

          // default parsers
        } else {
          let nValue = Number(value);

          if (!isNaN(nValue)) {
            return nValue;
          }

          let bValue = value.toLowerCase();

          if (bValue === 'true') {
            return true;
          } else if (bValue === 'false') {
            return false;
          }
          return value;
        }
      };

      // recursive function for retrieving the full path
      const getParentPath = function (parentElement) {
        if (parentElement?._parent?._parent) {
          const parentKeys = Object.keys(parentElement._parent._parent);
          const parentName = parentKeys[parentKeys.length - 1];

          if (parentElement._parent._parent._parent) {
            const parentPath = getParentPath(parentElement._parent);
            return `${parentPath ? `${parentPath}.` : ''}${parentName}`;
          } else {
            return parentName;
          }
        }

        return null;
      };

      // remove _text attribute
      const removeJsonTextAttribute = function (value, parentElement) {
        try {
          // only iterate for parent path if schema is declared
          const parentPath = responseSchema
            ? getParentPath(parentElement)
            : null;

          const popKeys = Object.keys(parentElement._parent);
          const keyNo = popKeys.length;
          const keyName = popKeys[keyNo - 1];
          const arrOfKey = parentElement._parent[keyName];
          const arrOfKeyLen = arrOfKey.length;
          if (arrOfKeyLen > 0) {
            const arr = arrOfKey;
            const arrIndex = arrOfKey.length - 1;
            arr[arrIndex] =
              keyName === 'name'
                ? value
                : nativeType(value, keyName, parentPath);
          } else {
            parentElement._parent[keyName] =
              keyName === 'name'
                ? value
                : nativeType(value, keyName, parentPath);
          }
        } catch (e) {
          console.error('XML text conversion error');
        }
      };

      const isEmpty = function (value) {
        return (
          !value ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        );
      };

      // looks for array and empty matches
      const parseEmptyAndArrays = function (obj) {
        for (const [key, rule] of Object.entries(responseSchema)) {
          let value = _.get(obj, key);

          if (rule.isArray) {
            // force default to be an empty array
            if (isEmpty(value)) {
              value = [];
            }

            _.set(obj, key, upcast.to(value, 'array'));

            // validate childrens for defaul values
            if (rule.default !== undefined) {
              for (let [childKey, childValue] of Object.entries(value)) {
                if (isEmpty(childValue)) {
                  value[childKey] = rule.default;
                }
              }
            }
          } else if (rule.default !== undefined && isEmpty(value)) {
            _.set(obj, key, rule.default);
          }
        }

        return obj;
      };

      let output = JSON.parse(
        convert.xml2json(xml, {
          compact: true,
          ignoreDeclaration: true,
          textFn: removeJsonTextAttribute,
          cdataFn: removeJsonTextAttribute,
        })
      );

      if (responseSchema) {
        output = parseEmptyAndArrays(output);
      }

      return output;
    } catch (err) {
      return false;
    }
  }

  async parseErrorMessageFromBlob(blob) {
    try {
      const text = await blob.text();
      //gets the first p tag or message
      const pattern = blobErrorPattern;

      const match = pattern.exec(text);
      if (match && match[2]) {
        //Group 2 is the message itself
        return match[2];
      }
    } catch (e) {
      console.error(e);
      return null;
    }

    return null;
  }
}

export default FileCloud;
