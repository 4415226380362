import Vue from 'vue';
import _ from 'lodash';
import isValidDomain from 'is-valid-domain';

// import router from '../../router';

export default {
  namespaced: true,
  state: {
    licenses: [],
    singleLicense: {},
    viewAll: false,
    singleOrder: {}
  },
  mutations: {
    set: (state, payload) => {
      Object.keys(payload).forEach(key => {
        Vue.set(state, key, payload[key]);
      });
    },
    setLicense: (state, payload) => {
      Vue.set(state.singleLicense, payload.id, payload.data);
    },
    setOrder: (state, payload) => {
      Vue.set(state.singleOrder, payload.id, payload.data);
    },
  },
  actions: {
    async toggleViewAll(context) {
      context.commit('set', { viewAll: !context.state.viewAll });
    },
    async getSites(context) {

      const response = await this.state.core.client.get('core/getfulllicenses', {
        start: 0,
        end: 100
      });
      
      if (response.ok && response.data && (response.data.meta.total || response.data.meta.totalothers)) {
        let licenses = Array.isArray(response.data.license) ? response.data.license : [response.data.license];
        
        licenses = _.orderBy(licenses, ['ispending', 'istrial', 'issuedate'], ['desc', 'desc', 'desc']);
        licenses = licenses.map(license => {
          return {
            ...license,
            expirydate: license.expirydate ? (typeof license.expirydate === 'number' ? new Date(license.expirydate * 1000).getTime() : new Date(license.expirydate).getTime()) : '',
            issuedate: license.issuedate ? (typeof license.issuedate === 'number' ? new Date(license.issuedate * 1000).getTime() : new Date(license.issuedate).getTime()) : '',
            deploymenturl: license.deploymenturl && typeof license.deploymenturl === 'string' && license.deploymenturl !== '*' ? license.deploymenturl : '',
            iscommunity: license.licensetype === 18 || license.licensegroup === 'Community',
          }
        })

        context.commit('set', { licenses: licenses });
      }

      return response;

    },
    async getSite(context, id) {

      const response = await this.state.core.client.get('core/getlicenseinfo', {
        licenseid: id,
      });

      if(response.ok) {
        context.commit('setLicense', {
          id,
          data: response.data
        });

      }

      return response;

    },
    async startFreeTrial(context, payload) {

      const { customername } = this.state.core.user;

      const response = await this.state.core.client.post('core/starttrial', {
        ...payload,
        customername
      });

      if (response.ok) {
        await this.dispatch('core/updateUserInfo');
        await context.dispatch('getSites');
      }

      return response;

    },

    async resetAdminPassword(context, teamName) {
      const response = await this.state.core.client.get('core/accountrecoveryonlinebycustomer', { teamname: teamName });

      return response;
    },
    
    // Online Trials //

    async getOnlineTrialStatus() {
      return await this.state.core.client.get('core/gettrialsitestatus');
    },  

    async setCustomizationFile(context, { file, scope }) {
      
      const response = await this.state.core.client.postMultipart(
        'core/settrialcustomizationlogo',
        { file: file },
        { param: `CUSTOMIZATION_LOGO_${scope}` }
      );
      
      const parsedData = this.state.core.client.parseResponse(response.data);
            
      return {
        ...response,
        ...parsedData.commands.command
      };
    },
    
    async setCustomization(context, payload) {
      return await this.state.core.client.post(
        'core/settrialcustomizationdata',
        payload
      );
    },
    
    async inviteUsers(context, payload) {
      return await this.state.core.client.post(
        'core/inviteusers',
        payload
      );
    },
    
    async downloadLicensesInfo() {

      const response = await this.state.core.client.getPlain('core/exportalllicenseinfo');
      
      if(response.ok) {
        
        const csvContent = 'data:text/csv;charset=utf-8,'+response.data;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'licenses.csv');
        document.body.appendChild(link);

        link.click(); 
        
      }
      
      return response;

    },

  },
  getters: {
    getLicense: (state) => (id) => {

      // is a query by domain
      if (id === 'localhost' || isValidDomain(id)) {
        return _.find(state.licenses, { id, istrial: 0, ispending: 0 })
      }

      if(state.singleLicense[id]) {
        return state.singleLicense[id];
      }

      return _.find(state.licenses, { id, istrial: 0, ispending: 0 })
    },
    getTrial: (state) => (id) => {

      const licenseTypes = {
        server: 'OnPremise',
        online: 'Online',
        community: 'Community',
      }

      if (['community', 'server'].indexOf(id) > -1) {
        return _.find(state.licenses, { licensegroup: licenseTypes[id], istrial: 1, ispending: 0 })
      }

      // is a query by domain
      if (id === 'localhost' || isValidDomain(id)) {
        return _.find(state.licenses, { deploymenturl: id, istrial: 1, ispending: 0 })
      }

      return _.find(state.licenses, { id, istrial: 1, ispending: 0 })
    },
  }
};
