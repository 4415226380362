import Vue from 'vue';
import FileCloud from 'common/filecloud/client';

export default {
  namespaced: true,
  state: {
    client: new FileCloud(),
    verifiedGuest: {},

  },
  mutations: {
    set: (state, payload) => {
      Object.keys(payload).forEach(key => {
        Vue.set(state, key, payload[key]);
      });
    },
  },
  actions: {
    async init(context) {
      // set ready state
      context.commit('set', { ready: true });
    },

    async verifyguest(context, userid) {

      let response = await context.state.client.post('core/verifyguest', {userid});

      if(!response.ok && response.data.exists) {
        response.ok = true;
      }

      if (response.ok) {
        context.commit('set', { verifiedGuest: {...response.data, userid} });
      }

      return response;
    },

    async loginguest(context, {userid, password, code}) {

      let payload = {
        userid,
        ...(password && {password}),
        ...(code && {code})
      }

      const response = await context.state.client.post('core/loginguest', payload);

      return response;
    },

    async resendotp(context, userid){
      return await context.state.client.post('core/resendotp', {userid});
    }
  }
};
