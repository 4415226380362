import Vue from 'vue';

/* let mock = {
  vat_status: 2, 
  vat_number: 'GB28382832',
  billing_entity: 'IE'
} */

// import router from '../../router';

export default {
  namespaced: true,
  state: {
    products: [],
    singleOrder: {},
  },
  mutations: {
    set: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        Vue.set(state, key, payload[key]);
      });
    },
    setOrder: (state, payload) => {
      Vue.set(state.singleOrder, payload.id, {...payload.data});
    },
    resetOrderTax: (state, payload) => {
      Vue.set(
        state.singleOrder[payload],
        'order_total_due',
        state.singleOrder[payload].order_total_due - state.singleOrder[payload].order_tax_total
      );

      Vue.set(state.singleOrder[payload], 'order_tax_total', 0);
    },
  },
  actions: {
    async getProducts(context) {
      const response = await this.state.core.client.get(
        'core/getitemsforsalewithprice',
        {
          contexttype: 1,
          contextid: this.state.core.user.id,
        }
      );

      if (response.ok) {
        context.commit('set', { products: response.data.item });
      }

      return response;
    },
    async addItems(context, payload) {
      const response = await this.state.core.client.post(
        'core/addquicksaleorderitems',
        {
          contexttype: 1,
          contextid: this.state.core.user.id,
          ...payload,
        }
      );

      if (response.ok) {
        const { saleorder, order_items } = response.data;

        let items = Array.isArray(order_items.order_item)
          ? order_items.order_item
          : [order_items.order_item];

        context.commit('setOrder', {
          id: saleorder.order_number,
          data: {
            ...saleorder,
            items,
          },
        });
      }

      return response;
    },
    async getOrder(context, { number, accesscode }) {
      const response = await this.state.core.client.get(
        `core/getsaleorder${accesscode ? 'public' : ''}`,
        {
          ordernumber: number,
          accesscode,
        }
      );

      if (response.ok) {
        const { saleorder } = response.data;

        const itemsResponse = await this.state.core.client.get(
          `core/getsaleorderitems${accesscode ? 'public' : ''}`,
          {
            ordernumber: number,
            accesscode,
          }
        );

        let items = [];
        if (itemsResponse.data.order_items) {
          const { order_items } = itemsResponse.data;
          items = Array.isArray(order_items.order_item)
            ? order_items.order_item
            : [order_items.order_item];
        }

        context.commit('setOrder', {
          id: accesscode ? 'public' : saleorder.order_number,
          data: {
            ...saleorder,
            items,
          },
        });
      }

      return response;
    },

    async getProratedItemPrice(
      context,
      { licenseid, itemnumber, ordernumber }
    ) {
      const response = await this.state.core.client.get(
        'core/getprorateditemprice',
        {
          licenseid,
          itemnumber,
        }
      );

      if (response.data && response.data.result) {
        context.commit('setOrder', {
          id: ordernumber,
          data: {
            ...context.state.singleOrder[ordernumber],
            proratedPrice: response.data.result,
          },
        });
      }

      return response;
    },

    async updateBillingAddress(context, payload) {
      const noRefetch = payload.noRefetch;

      if (payload.noRefetch) {
        delete payload.noRefetch;
      }

      const response = await this.state.core.client.post(
        'core/updatesaleordercustomeraddressinfo',
        payload
      );

      if (response.ok && !noRefetch) {
        context.dispatch('getOrder', { number: payload.ordernumber });
      }

      return response;
    },
    async updateOrder(context, payload) {
      const response = await this.state.core.client.post(
        'core/updatesaleorder',
        payload
      );

      if (response.ok) {
        context.dispatch('getOrder', { number: payload.ordernumber });
      }

      return response;
    },
    async payOrder(context, payload) {
      const response = await this.state.core.client.post(
        `core/paysaleorder${payload.accesscode ? 'public' : ''}`,
        payload
      );

      if (response.ok) {
        context.dispatch('getOrder', {
          number: payload.ordernumber,
          accesscode: payload.accesscode,
        });

        // refresh sites list
        if (!payload.accesscode) {
          this.dispatch('sites/getSites');
        }
      }

      return response;
    },
    async getPublicQuote(context, code) {
      const response = await this.state.core.client.post(
        'core/getsaleorderpublic',
        {
          accesscode: code,
        }
      );

      if (response.ok) {
        const { saleorder } = response.data;

        const itemsResponse = await this.state.core.client.get(
          'core/getsaleorderitemspublic',
          {
            accesscode: code,
          }
        );

        const { order_items } = itemsResponse.data;

        let items = Array.isArray(order_items.order_item)
          ? order_items.order_item
          : [order_items.order_item];

        context.commit('setOrder', {
          id: 'public',
          data: {
            ...saleorder,
            items,
          },
        });
      }
    },
    async downloadQuote(context, { code, orderNumber }) {
      const response = await this.state.core.client.getBlob(
        `core/printsaleorder${code ? 'public' : ''}`,
        {
          accesscode: code,
          ordernumber: orderNumber,
        }
      );

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${orderNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async getSaleOrderCart(context) {
      const orderRequest = await this.state.core.client.post(
        'core/getsaleorderinshoppingcart'
      );
      if (!orderRequest.ok) throw orderRequest.data.message;

      const { saleorder } = orderRequest.data;

      context.commit('setOrder', {
        id: saleorder.order_number,
        data: {
          ...saleorder,
        },
      });

      return saleorder;
    },
    async addLicenseSeats(
      context,
      { licenseId, orderNumber, quantity, clearcart }
    ) {
      try {
        //add licenses to cart
        const additionalLicenseRequest = await this.state.core.client.post(
          'core/addadditionallicenseorderitem',
          {
            ordernumber: orderNumber,
            licenseid: licenseId,
            quantity,
            ...(clearcart ? { clearcart: 1 } : false),
          }
        );
        if (!additionalLicenseRequest.ok)
          throw additionalLicenseRequest.data.message;

        //fetch order from cart
        const shoppingCartRequest = await this.state.core.client.post(
          'core/getsaleorderinshoppingcart'
        );
        if (!shoppingCartRequest.ok) throw shoppingCartRequest.data.message;
        const saleorder = shoppingCartRequest.data.saleorder;

        //fetch items from cart
        const orderItemsRequest = await this.state.core.client.post(
          'core/getsaleorderitems',
          { ordernumber: saleorder.order_number }
        );
        if (!orderItemsRequest.ok) throw orderItemsRequest.data.message;
        const { order_items } = orderItemsRequest.data;
        const items = Array.isArray(order_items.order_item)
          ? order_items.order_item
          : [order_items.order_item];

        //update store
        context.commit('setOrder', {
          id: saleorder.order_number,
          data: {
            ...saleorder,
            items,
          },
        });

        return {
          ok: true,
          error: '',
        };
      } catch (error) {
        return {
          ok: false,
          error: error,
        };
      }
    },
    async renewLicense(context, licenseId) {
      try {
        console.log('getting shopping cart information');
        const orderRequest = await this.state.core.client.post(
          'core/getsaleorderinshoppingcart'
        );
        if (!orderRequest.ok) throw orderRequest.data.message;
        let { saleorder } = orderRequest.data;

        //if there are any items in cart, delete them
        let orderItemsRequest = await this.state.core.client.post(
          'core/getsaleorderitems',
          { ordernumber: saleorder.order_number }
        );
        console.log(orderItemsRequest.data);
        if (
          orderItemsRequest.ok &&
          orderItemsRequest.data.order_items &&
          orderItemsRequest.data.order_items.order_item
        ) {
          console.log('deleting items');
          const { order_items } = orderItemsRequest.data;
          const items = Array.isArray(order_items.order_item)
            ? order_items.order_item
            : [order_items.order_item];
          items.map((item) => {
            return this.state.core.client.post('core/deletesaleorderitem', {
              ordernumber: saleorder.order_number,
              orderitemid: item.id,
            });
          });
          await Promise.all(items);

          orderItemsRequest = await this.state.core.client.post(
            'core/getsaleorderitems',
            { ordernumber: saleorder.order_number }
          );
          console.log('items after update', orderItemsRequest.data);
        }

        console.log('add license to order');
        const addLicenseOrderRequest = await this.state.core.client.post(
          'core/addlicenserenewalorderitem',
          {
            ordernumber: orderRequest.data.saleorder.order_number,
            licenseid: licenseId,
            clearcart: 1,
          }
        );
        if (!addLicenseOrderRequest.ok)
          throw addLicenseOrderRequest.data.message;

        const shoppingCartRequest = await this.state.core.client.post(
          'core/getsaleorderinshoppingcart'
        );
        if (!shoppingCartRequest.ok) throw shoppingCartRequest.data.message;
        saleorder = shoppingCartRequest.data.saleorder;
        console.log(saleorder);

        orderItemsRequest = await this.state.core.client.post(
          'core/getsaleorderitems',
          { ordernumber: saleorder.order_number }
        );
        if (!orderItemsRequest.ok) throw orderItemsRequest.data.message;

        const { order_items } = orderItemsRequest.data;

        const items = Array.isArray(order_items.order_item)
          ? order_items.order_item
          : [order_items.order_item];

        context.commit('setOrder', {
          id: saleorder.order_number,
          data: {
            ...saleorder,
            items,
          },
        });

        return saleorder;
      } catch (error) {
        Vue.$toast.error(error);
      }
    },
    resetOrderTax(context, orderNumber) {
      context.commit('resetOrderTax', orderNumber);
    },
    async validateVatNumber(context, vatnumber) {
      const request = await this.state.core.client.post(
        'core/validatevatnumber', 
        {vatnumber}
      );

     return request;
    }
  },
  getters: {
    products: (state) => {

      // console.log(state.products)

      let output = {
        other: { items: [] },
        support: {}
      };

      state.products.forEach((item) => {

        if(item.displaygroup === 'support') {
          output.support[item.item_sku] = item;
          return;
        }

        if (!output[item.displaygroup]) {
          output[item.displaygroup] = { items: [] };
        }

        if (item.type && item.type === 'community') {
          output.other.items.push({
            ...item,
            description: `FileCloud Community Edition: ${item.description}`,
          });
        }
        if (item.type && typeof item.type === 'string') {
          output[item.displaygroup][item.type] = item;
        } else {
          output[item.displaygroup].items.push(item);
        }
      });

      return output;
    },
    productByLicenseType: (state) => (id) => {
      return state.products.find((product) => product.number === id);
    },
  },
};
