<template>
  <div class="form-group col-sm" :class="{ [`is-invalid`]: errors[id] }">
    <label v-if="label" :class="{[`is-optional`]: optional}" :for="id">{{ label }} <small v-if="optional">{{$t('Optional')}}</small></label>
    <slot name="info"></slot>
    <div class="input-group input-group-radio">
      <slot />
    </div>
    <small v-if="errors[id]" class="form-text text-danger" v-html="errors[id].message"></small>
    <small v-if="description" class="form-text" v-html="description"></small>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  props: {
    id: {
      type: String,
      default: uuid()
    },
    value: {type: [String, Number], default: ''},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    inputClass: {type: String, default: ''},
    description: {type: String, default: ''},
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    focus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    // if enabled will strictly show the optional message
    optional: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    // auto select text when focusing input
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEmpty: false
    }
  },
};
</script>
<style lang="scss">

.input-group-radio {
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: left;

  .form-check {
    color: var(--text-medium);
    height: auto;
    font-size: 0.9rem;
    margin-bottom:.5rem;
    label {
      font-weight:500;
    }
    small {
      display: block;
      color:var(--text-light);
    }
  }
}

label.is-optional {
  display:flex;
  align-items:center;
  small {
    font-size:.7rem;
    font-weight:500;
    margin-left:auto;
    color:var(--text-light);
  }
}

</style>
