let schemas = {};

schemas['core/verifyguest'] = {
  userid: {
    type: 'email',
    empty: false
  }
};

schemas['core/loginguest'] = {
  userid: {
    type: 'email',
    empty: false
  },
  code: {
    type: 'string',
    empty:false,
    optional: true,
  },
  password: {
    type: 'string',
    empty:false,
    optional: true,
  }
}

schemas['admin/addpendingrecord'] = {
  name: {
    type: 'string',
    empty: false
  },
  email: {
    type: 'email',
    empty: false
  },
  phone: {
    type: 'string',
    empty: false
  },
  country: {
    type: 'string',
    empty: false
  },
  producttype: {
    type: 'number',
    empty: false,
  },
  company: {
    type: 'string',
    empty:false,
  },
  instance_password: {
    type: 'string',
    empty:false,
    optional:true,
  },
  tos: {
    type: 'equal', 
    value: true, 
    strict: true,
    messages: {
      equalValue: 'You must read and agree in order to proceed',
    }
  }
}

schemas['admin/addpendingrecord?community=true'] = {
  name: {
    type: 'string',
    empty: false
  },
  email: {
    type: 'email',
    empty: false
  },
  country: {
    type: 'string',
    empty: false
  },
  producttype: {
    type: 'number',
    empty: false,
  },
  company: {
    type: 'string',
    optional: true
  },
  instance_password: {
    type: 'string',
    empty:false,
    optional:true,
  },
  usagetype: {
    type: 'string',
    empty: false,
  },
  tos: {
    type: 'equal', 
    value: true, 
    strict: true,
    messages: {
      equalValue: 'You must read and agree in order to proceed',
    }
  }
}


schemas['core/updatecustomerprofile'] = {
  country: {
    type: 'string',
    empty: false
  },
  industry: {
    type: 'string',
    empty: false
  },
  purchasetimeframe: {
    type: 'string',
    empty: false
  },
  phone: {
    type: 'string',
    empty: false,
  },
  department: {
    type: 'string',
    optional:true
  },
  role: {
    type: 'string',
    optional:true
  },
  licensecount: {
    type: 'string',
    optional:true
  },
  heardvia: {
    type: 'string',
    optional:true
  },
  password: {
    type: 'string',
    optional:true,
    min: 8,
  }
}

schemas['core/updatecustomerprofile?community=true'] = {
  country: {
    type: 'string',
    empty: false
  },
  company: {
    type: 'string',
    empty: false
  },
  password: {
    type: 'string',
    optional:true,
    min: 8,
  }
}

schemas['core/updatesaleordercustomeraddressinfo'] = {
  country: {
    type: 'string',
    empty: false
  },
  billing_entity: {
    type: 'string',
    optional: true,
  },
  address1: {
    type: 'string',
    empty: false
  },
  city: {
    type: 'string',
    empty: false
  },
  state: {
    type: 'string',
    empty: false
  },
  zip: {
    type: 'string',
    empty: false
  }
}

schemas['core/updatepassword'] = {
  oldpassword: {
    type: 'string',
    optional: true,
  },
  newpassword: {
    type: 'string',
    min: 8,
  },
  newpasswordconfirm: {
    type: 'equal',
    field: 'newpassword',
    min: 8,
    messages: {
      equalField: 'New Passwords do not match!',
    }
  },
}

export default schemas;
