<template>
  <div class="details-section">
    <Loader
      v-if="(isFetching && !order) || isRenewing"
      loading
      :title="$t('Fetching billing details...')"
    />
    <form v-else-if="order" @submit.prevent="onClickPrimary">
      <Loader v-if="isLoading" loading full />

      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>

      <h4 class="section-title">
        {{ $t('Billing Details') }}
        <small
          ><a v-if="!payOnly && !isPaid" @click="onEditBilling">{{
            $t('Edit Billing Details')
          }}</a></small
        >
      </h4>

      <div class="billing-details-section">
        <h4>{{ order.company_name }}</h4>
        <p>{{ order.customer_address1 }}</p>
        <p v-if="typeof order.customer_address2 === 'string'">
          {{ order.customer_address2 }}
        </p>
        <p>
          {{ order.customer_city }} / {{ order.customer_state }} /
          {{ order.customer_zip }}
        </p>
        <p>{{ order.customer_country }}</p>
      </div>

      <h4 v-if="!payOnly" class="section-title">{{ $t('Additional Info') }}</h4>

      <div v-if="!payOnly" class="row mb-2">
        <InputBox
          id="ponumber"
          v-model="form.ponumber"
          :label="$t('P.O. Number')"
          :disabled="payOnly || isPaid"
          optional
          :errors="errors"
          auto-select
        />
        <InputBox
          id="note"
          v-model="form.notes"
          :label="$t('Notes')"
          :disabled="payOnly || isPaid"
          optional
          :errors="errors"
          auto-select
        />
      </div>

      <h4 class="section-title">{{ $t('Items') }}</h4>

      <ul class="summary-list mb-0">
        <li v-for="item in order.items" :key="item.id">
          <div class="item-description">
            <h4>{{ item.item_quantity }}x {{ item.item_name }}</h4>
            <p>{{ item.item_description }}</p>
          </div>
          <div class="item-amount">
            <strike v-if="item.item_discount_percent">{{
              formatInlinePrice(item.item_quantity * item.unit_item_rate)
            }}</strike>
            <span v-if="item.item_discount_percent" class="badge">{{
              $tc('{count}% off', item.item_discount_percent)
            }}</span>
            {{ formatInlinePrice(item.item_amount) }}
          </div>
        </li>
      </ul>

      <div class="divider"></div>

      <div class="subtotal-section mt-4">
        <div class="total-description">
          <h4>{{ $t('Invoice Number') }}</h4>
        </div>
        <strong class="pricing">{{ order.order_number }}</strong>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>{{ $t('Order Sub Total') }}</h4>
        </div>
        <div class="pricing">
          {{ formatInlinePrice(order.order_item_subtotal) }}
        </div>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>
            {{ $t(`${order.billing_entity === 'US' ? 'Sales' : 'VAT'} Tax`) }}
          </h4>
        </div>
        <div class="pricing">
          {{ formatInlinePrice(order.order_tax_total) }}
        </div>
      </div>
      <div
        v-if="order.vat_status === 2 && order.billing_entity === 'IE'"
        class="subtotal-section"
      >
        <div v-if="!showVatField" class="total-description">
          <a @click="showVatField = true">{{
            $t('Do you have a vat number?')
          }}</a>
        </div>
        <div v-else class="row total-description">
          <InputBox
            v-model="billing.vatnumber"
            :label="$t('VAT Number')"
            :errors="errors"
            @keydown="debounceSave()"
          />
        </div>
        <div class="pricing">
          <a v-if="showVatField" @click="cancelVatInput()">{{
            $t('Cancel')
          }}</a>
        </div>
      </div>
      <div v-if="order.payment_received_total > 0" class="subtotal-section">
        <div class="total-description">
          <h4>{{ $t('Total Paid') }}</h4>
        </div>
        <div class="pricing">
          - {{ formatInlinePrice(order.payment_received_total) }}
        </div>
      </div>
      <div class="total-section">
        <div class="total-description">
          <h4>{{ $t('Total Due') }}</h4>
          <p>{{ $t('All prices are in U.S. Dollars') }}</p>
        </div>
        <div class="pricing">
          <div
            class="price"
            v-html="
              formatInlinePrice(
                order.order_total_due - order.payment_received_total
              )
            "
          ></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import Loader from 'common/components/Loader';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';
import countries from '@/constants/countries';
import entities from '@/constants/entities';
import states from '@/constants/states';

import _ from 'lodash';

export default {
  name: 'Summary',
  components: {
    Loader,
    InputBox,
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {},
    },
    payOnly: {
      type: Boolean,
      default: false,
    },
    quoteCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      billing: {
        country: '',
        state: '',
        billing_entity: '',
        vatnumber: '',
      },
      form: {},
      showVatField: false,
      isEditingBilling: false,
      errors: {},
      countries,
      states,
      entities,
    };
  },
  computed: {
    order() {
      return this.$store.state.checkout.singleOrder[
        this.currentProps.orderNumber
      ];
    },
    isRenewing() {
      return this.$store.state.loading['checkout/renewLicense'];
    },
    isFetching() {
      return this.$store.state.loading['checkout/getOrder'];
    },
    isLoading() {
      return (
        this.$store.state.loading['core/getSystemStatus'] ||
        this.$store.state.loading['checkout/updateBillingAddress'] ||
        this.$store.state.loading['checkout/updateOrder'] ||
        this.$store.state.loading['checkout/getOrder']
      );
    },
    isPaid() {
      return this.order && typeof this.order.fullpaymentreceived === 'number'
        ? !!this.order.fullpaymentreceived
        : false;
    },
  },
  async mounted() {
    await this.$store.dispatch('checkout/getOrder', {
      number: this.currentProps.orderNumber,
      accesscode: this.quoteCode,
    });

    // check if address is filled
    if (this.order && typeof this.order.customer_address1 !== 'string') {
      this.$emit('changeStep', { step: 'address' });
    }

    this.form = {
      notes:
        this.order && typeof this.order.notes === 'string'
          ? this.order.notes
          : '',
      ponumber:
        this.order && typeof this.order.po_number !== 'object'
          ? this.order.po_number
          : '',
    };

    this.$emit('settings', {
      secondary:
        this.payOnly || this.isPaid
          ? this.isPaid
            ? 'Download Invoice'
            : 'Download Quote'
          : 'Save Quote',
      primary:
        this.order && this.order.fullpaymentreceived === 1 ? null : 'Checkout',
    });

    if (this.order.vat_status === 2 && this.order.vat_number) {
      this.showVatField = true;
      this.billing.vatnumber = this.order.vat_number;
    }
  },
  methods: {
    async onClickPrimary() {
      if (this.payOnly) {
        this.$emit('changeStep', { step: 'payment' });
        return;
      }

      const hasSaved = await this.save();

      if (hasSaved) {
        this.$emit('changeStep', { step: 'payment' });
      }
    },

    async onClickSecondary() {
      // save pdf
      if (this.payOnly || this.isPaid) {
        await this.$store.dispatch('checkout/downloadQuote', {
          code: this.quoteCode ? this.quoteCode : undefined,
          orderNumber: this.order.order_number
            ? this.order.order_number
            : undefined,
        });
      } else {
        // save with status 2 = quote
        const hasSaved = await this.save(2);

        if (hasSaved) {
          this.$router.push('/billing');
          this.$modal.hide('checkout');
        }
      }
    },

    // save billing / additional info
    async save(status) {
      this.errors = {};

      if (this.isEditingBilling) {
        const billingResponse = await this.$store.dispatch(
          'checkout/updateBillingAddress',
          {
            noRefetch: true,
            ordernumber: this.order.order_number,
            ...this.billing,
          }
        );
        if (!billingResponse.ok) {
          this.errors =
            typeof billingResponse.error === 'string'
              ? { _message: billingResponse.error }
              : billingResponse.error;
          return false;
        }
      }

      // update po and notes
      const updateResponse = await this.$store.dispatch(
        'checkout/updateOrder',
        {
          ordernumber: this.order.order_number,
          ponumber: this.form.ponumber ? this.form.ponumber : undefined,
          ponumberentered: this.form.ponumber && this.form.ponumber !== '',
          notes: this.form.notes ? this.form.notes : undefined,
          orderstatus: status ? status : undefined,
          ...this.billing,
        }
      );

      if (!updateResponse.ok) {
        this.errors =
          typeof updateResponse.error === 'string'
            ? { _message: updateResponse.error }
            : updateResponse.error;
        return false;
      }

      await this.$store.dispatch('core/getSystemStatus');

      return true;
    },
    onEditBilling() {
      this.$emit('changeStep', { step: 'address' });
    },
    cancelVatInput() {
      this.showVatField = false;
      this.billing.vatnumber = '';
    },
    debounceSave: _.debounce(async function () {
      this.save();
    }, 1000),
    formatInlinePrice,
    formatPrice,
  },
};
</script>
