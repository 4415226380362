<template>
  <div class="details-section">

    <Loader v-if="isLoading" loading full />

    <div class="modal-heading">
      <h2>{{product.name}}</h2>
      <p v-if="product.type !== 'community'">{{$t('Starting at {price} per user, per month, minimum {minimumQuantity} users', { price: formatInlinePrice(product.price / 12), minimumQuantity: product.minimumquantitynew })}}</p>
      <p v-else>{{$t('{price} per user, {minimumQuantity} users required', { price: formatInlinePrice(product.price), minimumQuantity: product.minimumquantitynew })}}</p>
    </div>
    
    <form @submit.prevent="onClickPrimary">
      
      <div v-if="errors._message" class="alert alert-danger">
        {{errors._message}}
      </div>
      
      <div v-if="product.type !== 'community'" class="row">
        <InputBox
          v-if="useInputForAmountOfUsers"
          id="quantity"
          v-model="form.quantity"
          :min="product.minimumquantitynew"
          :focus="true"
          :label="$t('Number of Users')"
          type="number"
          :errors="errors"
          auto-select />
        <SlideInputBox
          v-else-if="form.quantity > 0"
          id="quantity"
          v-model="form.quantity"
          :label="$t('Number of Users')"
          :hint="$t('Drag the slider below to change')"
          :handles="20"
          :range = "{
            'min': product.minimumquantitynew,
            'max': 100
          }"
          :min="product.minimumquantitynew"
          :step="1"
          :errors="errors" >
          <h4>{{parseInt(form.quantity)}} <small>{{$t('seats')}}</small></h4>
        </SlideInputBox>
      </div>

      <div v-if="support && support.length > 0" class="row">
        <RadioBox
          id="support-plan"
          v-model="form.support"
          optional
          :label="$t('Recommended Support')"
          :errors="errors">

            <div v-for="(item) in support" :key="item.item_sku" class="form-check" :value="item.item_sku">
              <input :id="item.item_sku" v-model="form.support" class="form-check-input" type="radio" name="support-plan" :value="item.item_sku">
              <label class="form-check-label" :for="item.item_sku">
                {{item.shortname}}
                <small>{{$t('{description} - {price} per user, per month.', { description: item.description, price: formatInlinePrice(item.price / 12) })}}</small>
              </label>
            </div>

            <div class="form-check">
              <input id="notNow" v-model="form.support" class="form-check-input" type="radio" name="support-plan" value="">
              <label class="form-check-label" for="notNow">
                {{$t('FileCloud Self Service Email Technical Support')}}
                <small>{{$t('Free')}}</small>
              </label>
            </div>

        </RadioBox>
      </div>

      <div v-if="currentProps.type !== 'spla'" class="row">
        <InputBox
          id="deploymenturl"
          v-model="form.deploymenturl"
          :label="$t('Licensed Site URL')"
          :hint="$t('To issue your license, we need the URL where you will access FileCloud.')"
          :placeholder="$t('files.yoursite.com')"
          :errors="errors" />
      </div>

      <div class="professional-services">
        <h4><Icon name="user-ninja" family="fad"/> {{$t('Need professional assistance?')}}</h4>
        <p v-html='$t("Professional services option available to assist with deployment, configuration, and customization. Please get in touch with us at <a href=\"mailto:sales@filecloud.com\">sales@filecloud.com</a>.")'></p>
      </div>

      <div class="total-section">
        <div class="total-description">
          <h4>{{$t('Total')}}</h4>
          <p>
            {{$t('Billed anually')}}
            <Icon
               v-tooltip="{
                 content: $t('For purchasing more than a year, please contact sales.'),
               }"
               class="ml-1"
               name="info-circle"
               family="fas"
             />
          </p>
        </div>
        <div class="pricing">
          <div class="price" v-html="formatPrice((form.quantity * product.price) + supportPrice)"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import InputBox from '@/components/InputBox';
import RadioBox from '@/components/RadioBox';
import SlideInputBox from '@/components/SlideInputBox';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';

export default {
  name: 'ServerDetails',
  components: {
    Loader,
    Icon,
    InputBox,
    RadioBox,
    SlideInputBox
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        quantity: 0,
        deploymenturl: '',
        support: ''
      },
      useInputForAmountOfUsers: false,
      errors: {}
    }
  },
  computed: {
    product() {
      if(this.currentProps.type === 'spla') {
        return this.products.serviceprovider.spla;
      }
      return this.products[this.currentProps.group] && this.products[this.currentProps.group][this.currentProps.type] ? this.products[this.currentProps.group][this.currentProps.type] : {}
    },
    isSpla() {
      return this.currentProps?.type === 'spla';
    },
    isLoading() {
      return this.$store.state.loading['checkout/addItems'];
    },
    support() {
      if(!this.product.support_skus) return [];
      return this.product.support_skus.split(',').map((sku) => {
        const item = this.products.support[sku];
        if(!item) return {};
        return {
          ...item,
          shortname: item.name?.split('-')[0].trim()
        }
      }).filter(({ allowusertobuy }) => allowusertobuy === 1)
    },
    defaultSupport() {
      const item = this.products.support[this.product.default_support_sku];
      return {
        ...item,
        shortname: item.name?.split('-')[0].trim()
      }
    },
    currentSupport() {
      return this.products.support[this.form.support]
    },
    supportPrice() {
      if(this.currentSupport && this.currentSupport.price) {
        return (this.form.quantity) * this.currentSupport.price;
      } else {
        return 0;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        if(form.quantity >= 100) {
          this.useInputForAmountOfUsers = true;
        }
      }
    }
  },
  mounted() {
    
    this.form.quantity = this.product.minimumquantitynew;
    
    this.form.support = this.product.default_support_sku;

    this.$emit('settings', {
      primary: 'Next',
      secondary: (this.currentProps.previousStep) ? 'Back' : 'Cancel'
    })
    
  },
  methods: {
    async onClickPrimary() {
      
      this.errors = {};
      
      if(this.form.quantity < this.product.minimumquantitynew) {
        this.errors = { quantity: { message: this.$t('The minimum number of seats for this license is {minimum}', { minimum: this.product.minimumquantitynew }) } }
        return;
      }
      
      if(!this.form.deploymenturl && !this.isSpla) {
        this.errors = { deploymenturl: { message: this.$t('You must select a deployment domain') } }
        return;
      }
      
      // remove https from domain
      if(this.form.deploymenturl.indexOf('://') > -1) {
        this.form.deploymenturl = this.form.deploymenturl.split('://')[1];
      }
        
      let payload = {
        ['items[0][number]']: this.product.number,
        ['items[0][name]']: this.product.name,
        ['items[0][desc]']: this.product.description,
        ['items[0][quantity]']: this.form.quantity,
        deploymenturl: !this.isSpla ? this.form.deploymenturl : undefined
      };

      if(this.form.support && this.currentSupport) {
        payload['items[1][number]'] = this.currentSupport.number;
        payload['items[1][name]'] = this.currentSupport.name;
        payload['items[1][desc]'] = this.currentSupport.description;
        payload['items[1][quantity]'] = this.form.quantity;
      }

      const response = await this.$store.dispatch('checkout/addItems', payload)
      
      if(!response.ok) {
        this.errors = typeof response.error === 'string' ? { _message: response.error } : response.error;
        return;
      }
            
      this.errors = {};
      
      this.$emit('changeStep', { step: 'address', props: { orderNumber: response.data.saleorder.order_number } });

    },
    onClickSecondary() {
      if(this.currentProps.previousStep) {
        this.$emit('changeStep', { step: this.currentProps.previousStep });
      } else {
        this.$modal.hide('checkout');
      }
    },
    formatInlinePrice,
    formatPrice
  }
};
</script>
