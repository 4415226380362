<template>
  <Modal
    name="checkout"
    :title="$t(modalTitle)"
    class="fixed-top checkout-modal"
    :width="step === 'pricing-online' || step === 'pricing-onprem' ? 1100 : 600"
    @before-open="onOpen"
    @closed="onClose"
  >
    <div>
      <Loader v-if="isLoading" loading :title="$t('Fetching pricing...')" />
      <component 
        :is="`${step}-section`" 
        v-else 
        :ref="'section'" 
        :products="products" 
        :current-props="currentProps" 
        @settings="onReceiveSettings" 
        @changeStep="onChangeStep"
      />
    </div>
    <template #footer>
      <div class="row" style="row-gap: 16px">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="d-flex col-12 col-sm-auto">
          <button v-if="secondary" class="btn btn-outline-primary px-sm-5 col" :class="{['mr-2']: primary}" @click="onClickSecondary">{{secondary}}</button>
          <button v-if="primary" class="btn btn-primary px-sm-5 col" @click="onClickPrimary">{{primary}}</button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';

import WelcomeSection from '@/components/Checkout/Welcome.vue';

// pricing
import PricingOnpremSection from '@/components/Checkout/Server.vue';
import PricingOnlineSection from '@/components/Checkout/Online.vue';
import DetailsOnpremSection from '@/components/Checkout/ServerDetails.vue';
import DetailsOnlineSection from '@/components/Checkout/OnlineDetails.vue';
import DetailsServiceproviderSection from '@/components/Checkout/ServiceProviderDetails.vue';
import DetailsOtherSection from '@/components/Checkout/OtherDetails.vue';

// import ServerDetails from '@/components/Checkout/ServerDetails.vue';

import AddressSection from '@/components/Checkout/BillingAddress.vue';
import SummarySection from '@/components/Checkout/Summary.vue';
import PaymentSection from '@/components/Checkout/Payment.vue';
import PaidSection from '@/components/Checkout/Paid.vue';

import RenewSection from '@/components/Checkout/Renew.vue';
import AddStorage from '@/components/Checkout/AddStorage.vue';

export default {
  name: 'Checkout',
  components: {
    Loader,
    Modal,
    SupportBlock,
    WelcomeSection,
    PricingOnpremSection,
    PricingOnlineSection,
    DetailsOnpremSection,
    DetailsOnlineSection,
    DetailsServiceproviderSection,
    DetailsOtherSection,
    AddressSection,
    SummarySection,
    PaymentSection,
    PaidSection,
    RenewSection,
    'addstorage-section': AddStorage,
  },
  data() {
    return {
      errors: {},
      primary: '',
      secondary: '',
      currentStep: null,
      previousStep: null,
      currentProps: {}
    }
  },
  computed: {
    modalTitle(){
      if(this.currentStep === 'welcome' || this.currentStep === 'pricing') return 'Buy FileCloud';
      if(this.currentStep === 'address') return 'Billing address';
      if(this.currentStep === 'summary') return 'Order summary';
      if(this.currentStep === 'details') return 'Order details';
      return 'Checkout';
    },
    isLoading() {
      return this.$store.state.loading['checkout/getProducts'];
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
    products() {
      return this.$store.getters['checkout/products'];
    },
    step() {
      
      if(this.currentStep === 'pricing' || this.currentStep === 'details') {        
        return `${this.currentStep}-${this.currentProps.group}`;
      } else if (this.currentStep) {
        return this.currentStep;
      }
      
      return 'welcome';
    }
  },
  mounted() {
    // this.$modal.show('checkout', { step: 'pricing-online', props: { group: 'online' } });
    // this.$modal.show('checkout', { step: 'details', props: { group: 'onprem', type: 'spla' } });
    // this.$modal.show('checkout', { step: 'paid', props: { orderNumber: 'CL-10001' } });
  },
  methods: {
    onOpen({ params }) {
      
      if(params && params.step) {
        this.currentProps = params.props ? params.props : {};
        this.currentStep = params.step;
      } else {
        this.currentStep = 'welcome';
      }

      this.$store.dispatch('checkout/getProducts');
    },
    onClose() {
      if(this.$route.name !== 'billing') return;

      this.$store.dispatch('core/getBillingInfo');
    },
    onChangeStep({ step, props }) {
      this.currentProps = { ...this.currentProps, previousStep: this.currentStep, ...props };
      this.currentStep = step;
    },
    onReceiveSettings(data) {
      this.primary = data.primary;
      this.secondary = data.secondary;
    },
    onClickPrimary() {
      this.$refs.section.onClickPrimary();
    },
    onClickSecondary() {
      this.$refs.section.onClickSecondary();
    }
  }
};
</script>
