<template>
  <div class="details-section">
    
    
    <Loader v-if="isLoading" loading full />
    <Loader v-if="isFetching && !order" loading :title="$t('Fetching billing details...')" />
    <form v-else-if="order" @submit.prevent="onClickPrimary">
      
      <div class="modal-hero">
        <h2>{{$t('Thank you for choosing FileCloud')}}</h2>
        <p>{{$t('We have sent your invoice via email, you will receive another email as soon as your license is ready.')}}</p>
      </div>
      
      <div v-if="errors._message" class="alert alert-danger">
        {{errors._message}}
      </div>
      
      <h4 class="section-title">{{$t('Items')}}</h4>

      <ul class="summary-list">
        <li v-for="item in order.items" :key="item.id">
          <div class="item-description">
            <h4>{{item.item_quantity}}x {{item.item_name}}</h4>
            <p>{{item.item_description}}</p>
          </div>
          <div class="item-amount">
            <strike v-if="item.item_discount_percent">{{formatInlinePrice(item.item_quantity * item.unit_item_rate)}}</strike>
            <span v-if="item.item_discount_percent" class="badge">{{$tc('{count}% off', item.item_discount_percent)}}</span> 
            {{formatInlinePrice(item.item_amount)}}
          </div>
        </li>
      </ul>
            
      <div class="divider"></div>
      <div class="subtotal-section mt-4">
        <div class="total-description">
          <h4>{{$t('Invoice Number')}}</h4>
        </div>
        <strong class="pricing">{{order.order_number}}</strong>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>{{$t('Order Sub Total')}}</h4>
        </div>
        <div class="pricing">{{formatInlinePrice(order.order_item_subtotal)}}</div>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>{{$t('Sales Tax')}}</h4>
        </div>
        <div class="pricing">{{formatInlinePrice(order.order_tax_total)}}</div>
      </div>
      <div v-if="order.payment_received_total > 0" class="subtotal-section">
        <div class="total-description">
          <h4>{{$t('Total Paid')}}</h4>
        </div>
        <div class="pricing">- {{formatInlinePrice(order.payment_received_total)}}</div>
      </div>
      <div class="total-section">
        <div class="total-description">
          <h4>{{$t('Total Due')}}</h4>
          <p>{{$t('All prices are in U.S. Dollars')}}</p>
        </div>
        <div class="pricing">
          <div class="price" v-html="formatInlinePrice(order.order_total_due - order.payment_received_total)"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import Loader from 'common/components/Loader';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';

export default {
  name: 'Paid',
  components: {
    Loader
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {}
    },
    quoteCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {},
      errors: {}
    }
  },
  computed: {
    order() {
      return this.$store.state.checkout.singleOrder[this.currentProps.orderNumber];
    },
    isFetching() {
      return this.$store.state.loading['checkout/getOrder'];
    },
    isLoading() {
      return this.isStripeLoading || this.$store.state.loading['checkout/payOrder'];
    }
  },
  async mounted() {
    
    if(!this.order) {
      await this.$store.dispatch('checkout/getOrder', { number: this.currentProps.orderNumber, accesscode: this.quoteCode });
    }
        
    this.$emit('settings', {
      secondary: this.quoteCode ? 'Download Invoice' : '',
      primary: this.quoteCode ? '' : 'Done'
    });
        
  },
  methods: {
    async onClickSecondary() {
      await this.$store.dispatch('checkout/downloadQuote', {
        code: this.quoteCode,
        orderNumber: this.order.order_number
      });
    },
    async onClickPrimary() {
      this.$modal.hide('checkout');
    },
    formatInlinePrice,
    formatPrice
  }
};
</script>
