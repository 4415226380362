export const addScript = async (url) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.addEventListener('load', resolve);
    script.setAttribute('src', url);
    document.head.appendChild(script);

  })
}

export const createAnalytics = async () => {

  const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
  const noTracking = localStorage.getItem('noTracking') || urlParams.has('no-tracking');

  if(noTracking) return;

  // Google Analytics
  await addScript('https://www.googletagmanager.com/gtag/js?id=G-6P2RLK453B');
  await addScript('https://www.googletagmanager.com/gtag/js?id=UA-1061363-7');

  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-6P2RLK453B');
  gtag('config', 'UA-1061363-7');
  window.gtag = gtag;

  // Matomo
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u='https://analytics.filecloud.com/';
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

}