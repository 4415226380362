<template>
  <div class="form-group col-sm" :class="{ [`is-invalid`]: errors[id] }">
    <label v-if="label" :for="id">{{ label }}{{ required ? '*' : '' }}</label>
    <vue-tel-input
      ref="phoneInput"
      :value="value"
      :input-options="inputOptions"
      @input="onInput"
      @country-changed="onCountryChanged"
      @open="isCountrySelectionOpen = true"
      @close="isCountrySelectionOpen = false"
    >
      <template v-if="activeCountry" slot="arrow-icon">
        <Icon v-if="!isCountrySelectionOpen" family="fas" name="caret-down" />
        <Icon v-else family="fas" name="caret-up" />
      </template>
    </vue-tel-input>
    <small v-if="errors[id]" class="form-text text-danger" v-html="errors[id].message"></small>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import { v4 as uuid } from 'uuid';
export default {
  components: {
    Icon,
  },
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uuid()
    },
  },
  data() {
    return {
      isCountrySelectionOpen: false,
      phone: '',
      activeCountry: {},
      isValidUSNumber: true,
      localErrors: this.errors,     // local copy of errors
    };
  },
  computed: {
    inputOptions() {
      return {
        placeholder: this.placeholder,
        id: this.id
      };
    },
  },
  watch: {
    'activeCountry.iso2': function (newValue) {
      if (newValue.toLowerCase() === 'us') {
        this.isValidUSNumber = this.validatePhoneNumberLength(this.value);
      }
    },
    value: function (newValue) {
      if (this.activeCountry.iso2.toLowerCase() === 'us') {
        this.isValidUSNumber = this.validatePhoneNumberLength(newValue);
        // local copy of errors
        this.localErrors[this.id] = this.isValidUSNumber ? {} : { message: 'Invalid phone number. A valid US number has 10 digits.' };
      }
    },
    // watcher to enforce update on prop change
    errors: {
      immediate: true,
      handler(newValue) {
        this.localErrors = { ...newValue };
      },
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
    onCountryChanged(country) {
      this.activeCountry = country;
    },
    validatePhoneNumberLength(phoneNumber) {
      const match = phoneNumber.match(/\d/g);
      const numberLength = match ? match.length : 0;
      return numberLength === 10;
    },
  },
};
</script>
