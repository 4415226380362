<template>
  <div>
    <a
      v-if="community || (user && user.iscommunityedition)"
      class="support-block"
      tabindex="-1"
      readonly
      :class="{ ['support-block--minified']: minified }"
      href="https://community.filecloud.com"
      target="_blank"
    >
      <Icon v-if="!minified" name="user-headset" family="fad" />
      <div class="content d-flex align-items-center">
        <h5 class="mb-0">{{ $t('Need help?') }}</h5>
      </div>
    </a>
    <a
      v-else-if="core.authenticated"
      class="support-block"
      tabindex="-1"
      readonly
      :class="{ ['support-block--minified']: minified }"
      @click="onClickButton"
    >
      <Icon v-if="!minified" name="user-headset" family="fad" />
      <div class="content">
        <h5>{{ $t('Need help?') }}</h5>
        <p>{{ $t('Contact us') }}</p>
      </div>
    </a>
    <a
      v-else
      class="support-block"
      tabindex="-1"
      readonly
      :class="{ ['support-block--minified']: minified }"
      href="https://www.filecloud.com/contactus"
      target="_blank"
    >
      <Icon v-if="!minified" name="user-headset" family="fad" />
      <div class="content">
        <h5>{{ $t('Need help?') }} {{ $t('Contact us') }}</h5>
      </div>
    </a>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'SupportBlock',
  components: {
    Icon,
  },
  props: {
    minified: {
      type: Boolean,
      default: false,
    },
    community: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.core.user;
    },
    core() {
      return this.$store.state.core;
    },
  },
  methods: {
    onClickButton() {
      this.$modal.show('contact-us');
    },
  },
};
</script>

<style lang="scss">
.support-block {
  display: flex;
  background: var(--bg-light);
  border: 1px solid var(--border-color);
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  text-decoration: none !important;
  &:hover {
    background: var(--color-primary);
    .inline-icon,
    h5,
    p {
      color: var(--color-primary--foreground);
      &:after {
        color: var(--color-primary--foreground);
        opacity: 0.5;
      }
    }
  }
  &.support-block--minified {
    background: transparent;
    border: none;
    padding: 0;
    display: inline-block;
    h5 {
      color: var(--color-primary);
    }
    &:hover {
      h5 {
        color: var(--color-primary);
      }
      p {
        color: var(--text-light);
      }
    }
  }
  .inline-icon {
    font-size: 2rem;
    color: var(--text-muted);
    margin-right: 1rem;
    &:after {
      color: var(--color-primary);
      opacity: 1;
    }
  }
  h5 {
    font-size: 0.9rem;
    color: var(--text-medium);
    margin-bottom: 0;
  }
  p {
    margin: 0;
    color: var(--text-light);
    font-size: 0.8rem;
  }
}
</style>
