<template>
  <Modal
    name="trial"
    :title="$t('Start Free Trial')"
    class="fixed-top checkout-modal"
  >
  
    <div class="welcome-section">
      
      <div class="modal-heading">
        <h2>{{ $t('FileCloud Trial') }}</h2>
        <p>{{ $t('Please choose the FileCloud solution that best suits you for a free trial (15-day trial online or 30-day trial server).') }}</p>
      </div>
      
      <Loader v-if="isLoading" loading full />

      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>
      
      <div class="row">
        <div class="col-12 col-sm-6">
          <a class="card p-3" href="https://filecloud.com/#onpremiseTrial?type=online" target="_blank">
            <div class="row no-gutters align-items-center">
              <Clouds class="col-4"/>
              <div class="col-8 d-flex flex-column">
                <h4>{{$t('Online')}}</h4>
                <span>{{$t('Run FileCloud on our secure infrastructure')}}</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <div class="card p-3" :class="{disabled: !user.istrialeligible}" @click="onSubmit(0)">
            <div class="row no-gutters align-items-center">
              <Warehouse class="col-4"/>
              <div class="col-8 d-flex flex-column">
                <h4>{{$t('Server')}}</h4>
                <span v-if="user.istrialeligible">{{$t('Run FileCloud on your own infrastructure')}}</span>
                <span v-else>{{$t('Contact Sales if you are interested in a new trial.')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary px-5 ml-auto" @click="onClose">
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';

import Clouds from '@/assets/clouds.svg';
import Warehouse from '@/assets/warehouse.svg'

export default {
  name: 'Checkout',
  components: {
    Loader,
    Modal,
    SupportBlock,
    Clouds,
    Warehouse
  },
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['sites/startFreeTrial'];
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  methods: {
    async onSubmit(type) {
      const response = await this.$store.dispatch('sites/startFreeTrial', {
        trialtype: type, // 1 for community
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }
      
      this.$router.push(`/sites/trial/${(type === 0) ? 'server' : 'community'}?getstarted=true`)

      this.$modal.hide('trial');
    },
    onClose() {
      this.$modal.hide('trial');
    },
  },
};
</script>
