<template>
  <Modal
    name="contact-us"
    :title="$t('Contact Us')"
    class="fixed-top checkout-modal contact-modal"
    @before-open="onOpen"
  >
    <div class="product-section">
      <Loader v-if="isLoading" loading full />

      <div class="row">
        <div class="col">
          <div class="contact-block" :class="{['has-border']: !isSale}">
            <div class="contact-block-header">
              <h4>
                {{ $t('Sales') }}
                <template v-if="isAvailable && !isSale">
                  <small>{{ $t('Available Now') }}</small>
                  <Icon
                    v-tooltip="{
                      content: $t('Available on Mon-Fri 9 AM to 5 PM US CST'),
                    }"
                    class="ml-1"
                    name="info-circle"
                    family="fas"
                  />
                </template>
                <small v-else-if="!isSale">{{ $t('Mon-Fri 9 AM to 5 PM US CST') }}</small>
              </h4>
              <p v-if="isLegacy">
                {{ $t('Please contact your account manager / CSM for assistance or contact sales for renewals/upgrades.') }}
              </p>
             <p v-else-if="isStorage">
                {{ $t('To buy extra storage please contact sales.') }}
              </p>
              <p v-else>
                {{ $t('Questions, feedbacks, comments about your license?') }}
              </p>
            </div>
            <ul class="contact-menu">
              <li>
                <a
                  v-if="!isLegacy"
                  :class="{ ['disabled']: !isAvailable }"
                  @click="onOpenChat"
                  ><Icon name="comments-alt" family="fas" /> Chat Now
                  <Icon
                    v-tooltip="{
                      content: $t(
                        'Our chat is only available during the working hours: Mon-Fri 9 AM to 5 PM US CST'
                      ),
                    }"
                    :class="{ ['ml-auto']: !isAvailable }"
                    name="info-circle"
                    family="fas"
                  />
                </a>
              </li>
              <li v-if="!isSale">
                <a target="_blank" href="https://www.filecloud.com/sales-team/" @click="onScheduleDemo"
                  ><Icon name="mouse-pointer" family="fas" />{{
                    $t('Schedule a free demo')
                  }}</a
                >
              </li>
              <li>
                <a href="mailto:sales@filecloud.com"
                  ><Icon name="mailbox" family="fas" /> sales@filecloud.com</a
                >
              </li>
              <li>
                <a v-if="!isLegacy" href="tel:+1888571-6480"
                  ><Icon name="phone" family="fas" /> +1 (888) 571-6480</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isSale" class="col">
          <div class="contact-block">
            <div class="contact-block-header">
              <h4>
                {{ $t('Support') }}
                <template v-if="isAvailable">
                  <small>{{ $t('Available Now') }}</small>
                  <Icon
                    v-tooltip="{
                      content: $t('Available on Mon-Fri 9 AM to 5 PM US CST'),
                    }"
                    class="ml-1"
                    name="info-circle"
                    family="fas"
                  />
                </template>
                <small v-else>{{ $t('Mon-Fri 9 AM to 5 PM US CST') }}</small>
              </h4>
              <p>{{$t('Need help with FileCloud?')}}</p>
            </div>
            <ul class="contact-menu">
              <li>
                <a
                  href="https://www.filecloud.com/supportdocs/display/cloud/Contact+FileCloud+Support"
                  target="_blank"
                  ><Icon name="comments-alt" family="fas" /> Submit a Ticket</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="row">
        <div class="col-auto ml-auto">
          <button class="btn btn-outline-primary px-5 ml-auto" @click="onClose">
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import Icon from 'common/components/Icon.vue';
import Modal from '@/components/Modal.vue';
import moment from 'moment-timezone';

import { createOlark } from '../../olark';

export default {
  name: 'Checkout',
  components: {
    Loader,
    Modal,
    Icon,
  },
  data() {
    return {
      errors: {},
      isAvailable: false,
      isLegacy: null,
      isStorage: null,
      isLoadingChat: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['sites/startFreeTrial'] || this.isLoadingChat;
    },
    isSale() {
      return this.isLegacy || this.isStorage;
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  mounted() {
    createOlark(); // Now initializes Intercom instead of Olark
  },
  methods: {
    onOpen({ params }) {

      this.isLegacy = params && params.type === 'legacy';
      this.isStorage = params && params.type === 'storage';
      
      const timezone = moment().tz('America/Chicago');
      const dayOfWeek = timezone.format('e');
      const currentTime = timezone.format('H');

      this.isAvailable =
        dayOfWeek > 0 &&
        dayOfWeek < 6 && // mon-fri
        currentTime > 8 &&
        currentTime < 17
          ? true
          : false;

        this.$store.dispatch('core/sendAnalyticsEvent', {
          category: 'cerebro',
          action: 'contact-us',
          label: this.user.email,
        });

    },
    onScheduleDemo() {
      this.$store.dispatch('core/sendAnalyticsEvent', {
        category: 'cerebro',
        action: 'contact-us-demo',
        label: this.user.email,
      });
    },
    onOpenChat() {

      if(this.isAvailable) {
        // Initialize the Intercom Widget and display the chat
        createOlark(); // Ensures Intercom is loaded and configured

        // Open the Intercom messenger directly
        if (window.Intercom) {
          window.Intercom('show');

          this.$store.dispatch('core/sendAnalyticsEvent', {
            category: 'cerebro',
            action: 'contact-us-chat',
            label: this.user.email,
          });
        }
      }
    },
    onClose() {
      this.$modal.hide('contact-us');
    },
  },
};
</script>
