import Vue from 'vue';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    meta: {},
    reports: [],
    splitReports: {
      reports: [],
      meta: {}
    }
  },
  mutations: {
    set: (state, payload) => {
      Object.keys(payload).forEach(key => {
        Vue.set(state, key, payload[key]);
      });
    },
    setReport: (state, { id, exceptioncount }) => {
      const index = _.findIndex(state.reports, { id });
      Vue.set(state.reports[index], 'exceptioncount', exceptioncount);
    },
  },
  actions: {
    async getReports(context, { start, end }) {

      const response = await this.state.core.client.get('core/getsplareportsofcustomer', {
        start,
        end,
        customerid: this.state.core.user.id
      });
      
      if (response.ok) {        
        const reports = Array.isArray(response.data.reports) ? response.data.reports : (Object.keys(response.data.reports).length > 0) ? [ response.data.reports ] : [];
        context.commit('set', { meta: response.data.meta, reports: reports });
      }

    },
    
    async getNextReports(context) {
      
      const response = await this.state.core.client.get('core/getsplareportsofcustomer', {
        start: context.state.reports.length,
        end: context.state.reports.length + 10,
        customerid: this.state.core.user.id
      });
            
      if (response.ok) {
        const reports = Array.isArray(response.data.reports) ? response.data.reports : [];
        context.commit('set', { reports: [ ...context.state.reports, ...reports ] });
      }

    },
    
    async getSplitReports(context, { start, end, id }) {

      let response = await this.state.core.client.get('core/getsplasplitupreports', {
        id,
        start,
        end
      });
      
      if (response.ok) {
                
        const reports = Array.isArray(response.data.extreportitem) ? response.data.extreportitem : (Object.keys(response.data.extreportitem).length > 0) ? [ response.data.extreportitem ] : [];
        
        context.commit('set', { splitReports: {
          meta: response.data.meta,
          reports: reports
        }});
      }

    },
    
    async getNextSplitReports(context, { id }) {

      const response = await this.state.core.client.get('core/getsplasplitupreports', {
        id,
        start: context.state.splitReports.reports.length,
        end: context.state.splitReports.reports.length + 10,
      });
            
      if (response.ok) {
        
        const reports = Array.isArray(response.data.extreportitem) ? response.data.extreportitem : [ response.data.extreportitem ];
                
        context.commit('set', { splitReports: {
          meta: response.data.meta,
          reports: [ ...context.state.splitReports.reports, ...reports ]
        }});
      }

    },

    async editReport(context, payload) {

      const response = await this.state.core.client.post('core/updatesplareport', payload);

      if (response.ok) {
        context.commit('setReport', payload);
      }

      return response;
    }
  }
};
