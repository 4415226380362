export const regions = [
	{
		name: 'US East (Northern Virginia)',
		value: 'us-east-1'
	},
  {
		name: 'US West (Oregon)',
		value: 'us-west-2'
	},
  {
		name: 'Canada (Central)',
		value: 'ca-central-1'
	},
  {
		name: 'Europe (Ireland)',
		value: 'eu-west-1'
	},
  {
		name: 'Europe (Frankfurt)',
		value: 'eu-central-1'
	},
  {
		name: 'Middle East (Bahrain)',
		value: 'me-south-1'
	},
  {
		name: 'Asia Pacific (Hong Kong)',
		value: 'ap-east-1'
	},
  {
		name: 'Asia Pacific (Singapore)',
		value: 'ap-southeast-1'
	},
  {
		name: 'Asia Pacific (Sydney)',
		value: 'ap-southeast-2'
	},
  {
		name: 'Middle East (UAE)',
		value: 'me-central-1'
	},
]
