<template>
  <div class="product-section">
    <div class="modal-heading">
      <h2>{{$t('FileCloud Online')}}</h2>
      <p>{{$t('Run FileCloud on our secure infrastructure')}}</p>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <PricingCard
          :name="$t('Essentials')"
          :product="sectionProducts.essentials"
          :benefits="benefits.essentials"
          @select="onSelect('essentials')"
        />
      </div>
      <div class="col-12 col-md-4 mt-3 mt-md-0">
        <PricingCard
          :name="$t('Advanced')"
          :product="sectionProducts.advanced"
          :benefits="benefits.advanced"
          recommended
          @select="onSelect('advanced')"
        />
      </div>
      <div class="col-12 col-md-4 mt-3 mt-md-0">
        <PricingCard
          :name="$t('GovCloud')"
          :product="sectionProducts.govcloud"
          :benefits="benefits.govcloud"
          @select="onSelect('govcloud')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onlineBenefits } from '@/constants/checkout';
import PricingCard from '@/components/Checkout/PricingCard.vue';

export default {
  name: 'Online',
  components: {
    PricingCard
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      benefits: onlineBenefits
    };
  },
  computed: {
    sectionProducts() {
      return this.products[this.currentProps.group] ? this.products[this.currentProps.group] : {
        essentials: {},
        advanced: {},
      }
    }
  },
  mounted() {
    this.$emit('settings', {
      secondary: (this.currentProps.previousStep) ? 'Back' : null
    })
  },
  methods: {
    onSelect(type) {
      if(this.sectionProducts[type] && this.sectionProducts[type].allowusertobuy) {
        this.$emit('changeStep', { step: 'details', props: { type } });
      } else {
        this.$modal.show('contact-us');
      }
    },
    onClickSecondary() {
      this.$emit('changeStep', { step: 'welcome' });
    }
  }
};
</script>
