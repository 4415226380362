<template>
  <vue-modal
    :name="name"
    :class="className"
    adaptive
    :min-width="width"
    focus-trap
    :click-to-close="!noClose"
    v-bind="{...$props, ...$attrs}"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="$emit('closed', $event)"
   >
    <div class="modal-header">
      <h4>{{title}}</h4>
      <ul class="modal-nav">
        <li v-if="!noClose"><a class="btn btn-icon" @click="close"><Icon family="fas" name="times-circle" /></a></li>
      </ul>
    </div>
    <div class="modal-body" :class="{[`no-padding`]:theme===`noPadding`}">
      <slot></slot>
    </div>
    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </vue-modal>
</template>
<script>

import Icon from 'common/components/Icon.vue';

export default {
  components: {
    Icon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    width: {
      type: [ String, Number ],
      default: 600
    },
    full: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    theme:{
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  methods: {
    close: function() {
      this.$modal.hide(this.name);
    },
    opened: function() {
      this.$emit('opened', null);
    },
    beforeOpen: function(e) {
      this.$emit('before-open', e);
    },
    beforeClose: function(e) {
      this.$emit('before-close', e);
    }
  }
}
</script>
