var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{staticClass:"fixed-top checkout-modal contact-modal",attrs:{"name":"contact-us","title":_vm.$t('Contact Us')},on:{"before-open":_vm.onOpen},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto ml-auto"},[_c('button',{staticClass:"btn btn-outline-primary px-5 ml-auto",on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])])])]},proxy:true}])},[_c('div',{staticClass:"product-section"},[(_vm.isLoading)?_c('Loader',{attrs:{"loading":"","full":""}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"contact-block",class:( _obj = {}, _obj['has-border'] = !_vm.isSale, _obj )},[_c('div',{staticClass:"contact-block-header"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Sales'))+" "),(_vm.isAvailable && !_vm.isSale)?[_c('small',[_vm._v(_vm._s(_vm.$t('Available Now')))]),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('Available on Mon-Fri 9 AM to 5 PM US CST'),
                  }),expression:"{\n                    content: $t('Available on Mon-Fri 9 AM to 5 PM US CST'),\n                  }"}],staticClass:"ml-1",attrs:{"name":"info-circle","family":"fas"}})]:(!_vm.isSale)?_c('small',[_vm._v(_vm._s(_vm.$t('Mon-Fri 9 AM to 5 PM US CST')))]):_vm._e()],2),(_vm.isLegacy)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('Please contact your account manager / CSM for assistance or contact sales for renewals/upgrades.'))+" ")]):(_vm.isStorage)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('To buy extra storage please contact sales.'))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.$t('Questions, feedbacks, comments about your license?'))+" ")])]),_c('ul',{staticClass:"contact-menu"},[_c('li',[(!_vm.isLegacy)?_c('a',{class:( _obj$1 = {}, _obj$1['disabled'] = !_vm.isAvailable, _obj$1 ),on:{"click":_vm.onOpenChat}},[_c('Icon',{attrs:{"name":"comments-alt","family":"fas"}}),_vm._v(" Chat Now "),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t(
                      'Our chat is only available during the working hours: Mon-Fri 9 AM to 5 PM US CST'
                    ),
                  }),expression:"{\n                    content: $t(\n                      'Our chat is only available during the working hours: Mon-Fri 9 AM to 5 PM US CST'\n                    ),\n                  }"}],class:( _obj$2 = {}, _obj$2['ml-auto'] = !_vm.isAvailable, _obj$2 ),attrs:{"name":"info-circle","family":"fas"}})],1):_vm._e()]),(!_vm.isSale)?_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.filecloud.com/sales-team/"},on:{"click":_vm.onScheduleDemo}},[_c('Icon',{attrs:{"name":"mouse-pointer","family":"fas"}}),_vm._v(_vm._s(_vm.$t('Schedule a free demo')))],1)]):_vm._e(),_c('li',[_c('a',{attrs:{"href":"mailto:sales@filecloud.com"}},[_c('Icon',{attrs:{"name":"mailbox","family":"fas"}}),_vm._v(" sales@filecloud.com")],1)]),_c('li',[(!_vm.isLegacy)?_c('a',{attrs:{"href":"tel:+1888571-6480"}},[_c('Icon',{attrs:{"name":"phone","family":"fas"}}),_vm._v(" +1 (888) 571-6480")],1):_vm._e()])])])]),(!_vm.isSale)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"contact-block"},[_c('div',{staticClass:"contact-block-header"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Support'))+" "),(_vm.isAvailable)?[_c('small',[_vm._v(_vm._s(_vm.$t('Available Now')))]),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('Available on Mon-Fri 9 AM to 5 PM US CST'),
                  }),expression:"{\n                    content: $t('Available on Mon-Fri 9 AM to 5 PM US CST'),\n                  }"}],staticClass:"ml-1",attrs:{"name":"info-circle","family":"fas"}})]:_c('small',[_vm._v(_vm._s(_vm.$t('Mon-Fri 9 AM to 5 PM US CST')))])],2),_c('p',[_vm._v(_vm._s(_vm.$t('Need help with FileCloud?')))])]),_c('ul',{staticClass:"contact-menu"},[_c('li',[_c('a',{attrs:{"href":"https://www.filecloud.com/supportdocs/display/cloud/Contact+FileCloud+Support","target":"_blank"}},[_c('Icon',{attrs:{"name":"comments-alt","family":"fas"}}),_vm._v(" Submit a Ticket")],1)])])])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }