import Vue from 'vue';
import VueHeadful from 'vue-headful';
import VueShortkey from 'vue-shortkey';
import * as LottiePlayer from '@lottiefiles/lottie-player';
import { createAnalytics } from './analytics.js';

// css
import './scss/base.scss';

import store from './store';
import router from './router';
import './plugins.js';

import { i18n } from './i18n.js';

import App from './App.vue'

Vue.config.productionTip = false

// components
Vue.component('VueHeadful', VueHeadful);

Vue.use(VueShortkey, {
  prevent: ['input', 'textarea', '.editr--content', 'div[contenteditable]'],
});

Vue.use(LottiePlayer);

new Vue({
  render: h => h(App),
  i18n,
  store,
  router
}).$mount('#app')

// Analytics and Chat
createAnalytics();