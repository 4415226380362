<template>
  <div class="details-section">
    
    <Loader v-if="isLoading" loading full />

    <div class="modal-heading">
      <h2>{{$t('Service Providers')}}</h2>
      <p>{{$t('FileCloud enables partners to build their own white labeled file share, sync, and mobile access solution')}}</p>
    </div>
    
    <form @submit.prevent="onClickPrimary">
            
      <div v-if="errors._message" class="alert alert-danger">
        {{errors._message}}
      </div>
      
      <div class="row">
        <SelectBox
          id="package"
          v-model="form.package"
          :label="$t('Reselling Package')"
          :errors="errors">
            <option v-for="(pack, index) in packages" :key="pack.id" :value="index">{{pack.description}}</option>
        </SelectBox>
      </div>
      
      <div class="row">
        <InputBox
          v-if="useInputForAmountOfUsers"
          id="quantity"
          v-model="form.quantity"
          :focus="true"
          :label="$t('Quantity')"
          type="number"
          :errors="errors"
          auto-select />
        <SlideInputBox
          v-else-if="form.quantity > 0"
          id="quantity"
          v-model="form.quantity"
          :label="$t('Quantity')"
          :hint="$t('Drag the slider below to change')"
          :handles="20"
          :range = "{
            'min': 1,
            'max': 100
          }"
          :step="1"
          :errors="errors" >
          <h4>{{parseInt(form.quantity)}}</h4>
        </SlideInputBox>
      </div>
      <div class="total-section">
        <div class="total-description">
          <h4>{{$t('Total')}}</h4>
          <p>
            {{$t('Billed anually')}}
            <Icon
               v-tooltip="{
                 content: $t('For purchasing more than a year, please contact sales.'),
               }"
               class="ml-1"
               name="info-circle"
               family="fas"
             />
          </p>
        </div>
        <div class="pricing">
          <div class="price" v-html="formatPrice(form.quantity * product.price)"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import InputBox from '@/components/InputBox';
import SelectBox from '@/components/SelectBox';
import SlideInputBox from '@/components/SlideInputBox';

import { formatInlinePrice, formatPrice } from '@/utils/checkout';

export default {
  name: 'ServiceProviderDetails',
  components: {
    Loader,
    Icon,
    InputBox,
    SelectBox,
    SlideInputBox
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        package: 0,
        quantity: 0
      },
      useInputForAmountOfUsers: false,
      errors: {}
    }
  },
  computed: {
    packages() {
      return this.products[this.currentProps.group].items;
    },
    product() {
      return this.products[this.currentProps.group].items[this.form.package];
    },
    isLoading() {
      return this.$store.state.loading['checkout/addItems'];
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        if(form.quantity >= 100) {
          this.useInputForAmountOfUsers = true;
        }
      }
    }
  },
  mounted() {
    
    this.form.quantity = this.product.minimumquantitynew;
    
    this.$emit('settings', {
      primary: 'Checkout',
      secondary: (this.currentProps.previousStep) ? 'Back' : 'Cancel'
    })
    
  },
  methods: {
    async onClickPrimary() {
      
      this.errors = {}
              
      const response = await this.$store.dispatch('checkout/addItems', {
        ['items[0][number]']: this.product.number,
        ['items[0][name]']: this.product.name,
        ['items[0][desc]']: this.product.description,
        ['items[0][quantity]']: this.form.quantity
      })
      
      if(!response.ok) {
        this.errors = typeof response.error === 'string' ? { _message: response.error } : response.error;
        return;
      }
            
      this.errors = {};
      
      this.$emit('changeStep', { step: 'summary', props: { orderNumber: response.data.saleorder.order_number } });

    },
    onClickSecondary() {
      if(this.currentProps.previousStep) {
        this.$emit('changeStep', { step: this.currentProps.previousStep });
      } else {
        this.$modal.hide('checkout');
      }
    },
    formatInlinePrice,
    formatPrice
  }
};
</script>
