import Vue from 'vue';
import Vuex from 'vuex';

// modules
import core from './modules/core.js'; // auth and general app handling
import login from './modules/login.js'; // login
import loading from './modules/loading.js'; // loaders
import checkout from './modules/checkout.js';
import sites from './modules/sites.js';
import spla from './modules/spla.js';
// import workers from './modules/workers.js'; // webworkers

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    core,
    login,
    loading,
    checkout,
    sites,
    spla
    // workers
  },
});

// handle state loading
store.subscribeAction({
  before: (action) => {
    store.commit('loading/set', { type: action.type, status: true });
  },
  after: (action) => {
    store.commit('loading/set', { type: action.type, status: false });
  },
});

export default store;
