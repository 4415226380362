import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./pages/Dashboard.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/quote/:code',
      name: 'quote',
      component: () => import('./pages/Quote.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./pages/Auth.vue'),
      meta: { unauthenticated: true }, // unauthenticated only
    },
    {
      path: '/login/:section/:attributes?',
      name: 'loginSub',
      component: () => import('./pages/Auth.vue'),
      meta: { unauthenticated: true }, // unauthenticated only
    },
    /* {
      path: '/trial',
      name: 'trial',
      component: () => import('./pages/Trial.vue'),
      meta: { unauthenticated: true }, // unauthenticated only
    }, */
    {
      path: '/trial/:product/:section/:attributes?',
      name: 'trialSub',
      component: () => import('./pages/Trial.vue'),
      meta: { unauthenticated: true }, // unauthenticated only
    },
    {
      path: '/intro/:token',
      name: 'intro',
      component: () => import('./pages/Intro.vue'),
    },
    {
      path: '/billing/:order_number?',
      name: 'billing',
      component: () => import('./pages/Billing.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/sites/trial/:trialType',
      name: 'siteTrial',
      component: () => import('./pages/Site.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/sites/:site',
      name: 'site',
      component: () => import('./pages/Site.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/spla',
      name: 'spla',
      component: () => import('./pages/Spla.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: () => import('./pages/Downloads.vue'),
      meta: { authenticated: true },
    },
    {
      path: '/downloads/:section',
      name: 'downloads-sub',
      component: () => import('./pages/Downloads.vue'),
      meta: { authenticated: true },
    },
    {
      path: '*',
      name: 'dashboard-alt',
      component: () => import('./pages/Dashboard.vue'),
      meta: { authenticated: true },
    },
  ],
});

export default router;
