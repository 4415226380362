<template>
  <div class="container-fluid p-0" role="main">
    <router-view></router-view>
    <ContactUsModal />
  </div>
</template>

<script>

import ContactUsModal from '@/components/Modals/ContactUs.vue';

export default {
  name: 'MainLayout',
  components: {
    ContactUsModal
  }
}
</script>
