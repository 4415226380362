import axios from 'axios';

import Validator from 'fastest-validator';
import FileCloudClient from 'common/filecloud/client';

import schemas from './schemas';

class Cerebro extends FileCloudClient {
  constructor() {
    super();
                    
    this.url = (process.env.NODE_ENV === 'production') ? 
      '' : 
      '/api';
      
    this.schemas = schemas;
    // schema validator
    this.validator = new Validator({
      messages: {
        stringEmpty: 'This field must not be empty.',
        emailEmpty: 'This field must not be empty.',
        email: 'This field must be a valid email.',
        required: 'This field is required.',
        /*
        email: "general.errors.invalid-email",
        stringMin: "general.errors.string-min",
        */
      },
    });

    this.config = {
      ...this.config,
      xsrfCookieName: 'X-XSRFC-TOKEN',
      xsrfHeaderName: 'X-XSRFC-TOKEN'
    };
    
    this.config.headers['X-Requested-With'] = 'XMLHttpRequest';

    delete this.config.headers['X-XSRF-TOKEN'];

  }
  
  async postMultipart(
    endpoint,
    data,
    params = {},
    onUploadProgress = () => {},
    encryptedPass = ''
  ) {
    
    // parse multipart
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    
    headers['X-Requested-With'] = 'XMLHttpRequest';

    if (encryptedPass !== '') {
      headers['Encryption-Header'] = encryptedPass;
    }

    const source = axios.CancelToken.source();

    return await this.request(
      'POST',
      endpoint,
      formData,
      params,
      {
        headers,
        onUploadProgress: (e) => {
          onUploadProgress(e, source);
        },
        cancelToken: source.token,
      },
      true
    );
  }
  
}

export default Cerebro;
